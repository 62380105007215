/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { URLCombiner } from '../../../app/core/url-combiner/url-combiner';
import { getStatletsModulePath } from '../atmire-cua-routing-paths';

/**
 * Get the route to the page for a specific statlet
 */
export function getStatletPagePath(shortName: string) {
  return new URLCombiner(getStatletsModulePath(), shortName).toString();
}
