<div class="outer-wrapper" *ngIf="!shouldShowFullscreenLoader; else fullScreenLoader">
  <ds-themed-admin-sidebar></ds-themed-admin-sidebar>
  <div class="inner-wrapper"  [@slideSidebarPadding]="{
     value: (!(sidebarVisible | async) ? 'hidden' : (slideSidebarOver | async) ? 'shown' : 'expanded'),
     params: {collapsedSidebarWidth: (collapsedSidebarWidth | async), totalSidebarWidth: (totalSidebarWidth | async)}
    }">
    <ds-themed-header-navbar-wrapper></ds-themed-header-navbar-wrapper>

    <ds-notifications-board
      [options]="notificationOptions">
    </ds-notifications-board>
    <main class="main-content">
      <ds-themed-breadcrumbs></ds-themed-breadcrumbs>

      <div class="container d-flex justify-content-center align-items-center h-100" *ngIf="shouldShowRouteLoader">
        <ds-themed-loading [showMessage]="false"></ds-themed-loading>
      </div>
      <div [class.d-none]="shouldShowRouteLoader">
        <router-outlet></router-outlet>
      </div>
    </main>

    <ds-themed-footer></ds-themed-footer>
  </div>
</div>
<ng-template #fullScreenLoader>
  <div class="ds-full-screen-loader">
    <ds-themed-loading [showMessage]="false"></ds-themed-loading>
  </div>
</ng-template>
