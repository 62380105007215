/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { dataService } from '../../../../app/core/cache/builders/build-decorators';
import { DataService } from '../../../../app/core/data/data.service';
import { RequestService } from '../../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DSOChangeAnalyzer } from '../../../../app/core/data/dso-change-analyzer.service';
import { SEARCH_TERM } from '../search-reports-models/search-term.resource-type';
import { SearchTerm } from '../search-reports-models/search-term.model';

/**
 * The service handling all REST requests for SearchTerm
 */
@Injectable()
@dataService(SEARCH_TERM)
export class SearchTermsDataService extends DataService<SearchTerm> {
  protected linkPath = 'searchterms';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<SearchTerm>
  ) {
    super();
  }
}
