/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription, of, zip as observableZip } from 'rxjs';
import { AtmireValuePair } from '../atmire-value-pair.model';
import { AtmireValuePairDataService } from '../atmire-value-pair-data.service';
import { map, switchMap, take } from 'rxjs/operators';
import {
  hasNoValue,
  hasValue,
  hasValueOperator,
  isEmpty,
  isNotEmpty
} from '../../../../../app/shared/empty.util';
import {
  getAllSucceededRemoteDataPayload,
  getFirstSucceededRemoteDataPayload
} from '../../../../../app/core/shared/operators';


@Component({
  selector: 'ds-value-pairs-selector',
  styleUrls: ['./value-pairs-selector.component.scss'],
  templateUrl: './value-pairs-selector.component.html'
})
/**
 * A component rendering an input able to store multiple objects of certain types and the ability to dynamically search for them
 */
export class ValuePairsSelectorComponent implements OnInit, OnDestroy {
  /**
   * The list of DSpaceObject types to search for
   */
  @Input() searchEndpoint: string;

  @Input() searchHref: string;

  /**
   * A list of selected object values
   * These will be resolved into actual value-pairs in order to retrieve their display value
   */
  @Input() selectedValues: string[] = [];

  /**
   * Placeholder text to display
   */
  @Input() placeholder: string = null;

  /**
   * Whether or not the component should only emit on the addition of an object and not display any of the selected
   * objects within the input
   */
  @Input() emitOnAdd = false;

  /**
   * Emits the list of selected values whenever the user adds or removes an object
   */
  @Output() submitValues: EventEmitter<string[]> = new EventEmitter();

  /**
   * The initially selected ids. Used to compare and check whether sending an update is necessary.
   */
  initialSelectedValues: string[] = [];

  /**
   * The query entered by the user
   */
  query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * A list of options containing objects to display in the dropdown
   */
  options$: BehaviorSubject<AtmireValuePair[]> = new BehaviorSubject([]);

  /**
   * Whether or not data is loading
   * This will display a loading indicator when set to true
   */
  loading = false;

  /**
   * List of subscriptions to unsubscribe from
   */
  private subs: Subscription[] = [];

  constructor(protected valuePairService: AtmireValuePairDataService) {
  }

  ngOnInit(): void {
    this.initialSelectedValues = this.selectedValues;
    if (isEmpty(this.searchHref) && isNotEmpty(this.searchEndpoint)) {
      this.searchHref = this.valuePairService.getEndpointFromPath(this.searchEndpoint);
    }
    if (isNotEmpty(this.searchHref)) {
      this.updateOptionsFromQuery();
    }
  }

  /**
   * Subscribe to the query and update the options accordingly
   */
  updateOptionsFromQuery() {
    this.subs.push(
      // Update the list of options to display in the dropdown depending on the current query entered by the user
      this.query$.pipe(
        switchMap((query) => {
          this.loading = true;
          return this.valuePairService.findAllByHref(this.valuePairService.getEndpointWithDisplay(this.searchHref, query)).pipe(
            getAllSucceededRemoteDataPayload(),
            switchMap((list) => {
              const options = list.page;
              let selectedOptions$;
              if (isNotEmpty(this.selectedValues)) {
                selectedOptions$ = observableZip(...this.selectedValues.map((value) => this.valuePairService.findAllByHref(
                  this.valuePairService.getEndpointWithValue(this.searchHref, value)
                ).pipe(
                  getFirstSucceededRemoteDataPayload(),
                  map((l) => isNotEmpty(l.page) ? l.page[0] : null),
                  hasValueOperator(),
                )));
              } else {
                selectedOptions$ = of([]);
              }
              return selectedOptions$.pipe(
                take(1),
                map((selectedOptions: AtmireValuePair[]) => {
                  const selectedTopOptions = options.filter((option) => hasValue(selectedOptions.find((selectedOption) => selectedOption.id === option.id)));
                  const otherOptions = options.filter((option) => hasNoValue(selectedTopOptions.find((selectedTopOption) => selectedTopOption.id === option.id)));
                  const otherSelectedOptions = selectedOptions.filter((selectedOption) => hasNoValue(selectedTopOptions.find((selectedTopOption) => selectedTopOption.id === selectedOption.id)));
                  const finalOptions = [...selectedTopOptions];
                  if (isNotEmpty(query)) {
                    // If a query is provided, show the selected options from the list of results on top and the remaining results below
                    finalOptions.push(...otherOptions);
                  } else {
                    // If no query is provided, show the selected options from the list of results on top, the other selected options below them and the remaining results below those
                    finalOptions.push(...otherSelectedOptions.slice(0, 20 - finalOptions.length));
                    finalOptions.push(...otherOptions.slice(0, 20 - finalOptions.length));
                  }
                  return finalOptions;
                }),
              );
            })
          );
        }),
      ).subscribe((options: AtmireValuePair[]) => {
        this.loading = false;
        this.options$.next(options);
      }),
    );
  }

  /**
   * Send an update to the parent component
   * Only send an update if the values are different from the initially selected values
   * @param values
   */
  submit(values: string[]) {
    if (!this.valuesEqualInitial(values)) {
      this.submitValues.emit(values);
    }
  }

  /**
   * The list of selected IDs changed
   * emit if emitOnAdd is true
   */
  change() {
    if (this.emitOnAdd) {
      this.submitValues.emit(this.selectedValues);
      this.selectedValues = [];
    }
    this.reloadList();
  }

  /**
   * Reload the list by emitting the current query again
   * This is used to update the list of options whenever selectedIds updates
   */
  reloadList() {
    this.query$.next(this.query$.value);
  }

  /**
   * Method to check if a list of values match the list of initially selected values
   * @param values
   */
  valuesEqualInitial(values: string[]): boolean {
    return values.length === this.initialSelectedValues.length &&
      values.every((e) => this.initialSelectedValues.includes(e)) &&
      this.initialSelectedValues.every((e) => values.includes(e));
  }

  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
