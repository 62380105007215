import { Component, Input } from '@angular/core';
import { ThemedComponent } from '../../../theme-support/themed.component';
import { ItemDetailPreviewComponent } from './item-detail-preview.component';
import { Item } from '../../../../core/shared/item.model';
import { SearchResult } from '../../../search/models/search-result.model';
import { MyDspaceItemStatusType } from '../../../object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';

/**
 * This component show metadata for the given item object in the detail view.
 */
@Component({
  selector: 'ds-themed-item-detail-preview',
  styleUrls: [],
  templateUrl: '../../../../shared/theme-support/themed.component.html',
})
export class ThemedItemDetailPreviewComponent extends ThemedComponent<ItemDetailPreviewComponent> {

  @Input() item: Item;
  @Input() object: SearchResult<any>;
  @Input() status: MyDspaceItemStatusType;
  @Input() showSubmitter = false;

  protected inAndOutputNames: (keyof ItemDetailPreviewComponent & keyof this)[] = [
    'item',
    'object',
    'status',
    'showSubmitter',
  ];

  protected getComponentName(): string {
    return 'ItemDetailPreviewComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../themes/${themeName}/app/shared/object-detail/my-dspace-result-detail-element/item-detail-preview/item-detail-preview.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./item-detail-preview.component`);
  }
}
