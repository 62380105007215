<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngIf="item$ | async">
  <ds-atmire-item-list-preview
    [item]="item$ | async"
    [context]="context"
    [object]="object"
    [status]="status"></ds-atmire-item-list-preview>

  <ds-workspaceitem-actions [object]="dso" (processCompleted)="reloadedObject.emit($event.reloadedObject)"></ds-workspaceitem-actions>
</ng-container>
<ds-loading
  *ngIf="!(item$ | async)"
  [showMessage]="false"></ds-loading>
