<div class="card" [@focusShadow]="(isCollapsed$ | async)?'blur':'focus'">
  <ds-truncatable [id]="dso.id">
    <div class="position-absolute ml-1">
      <ng-content></ng-content>
    </div>
    <a *ngIf="linkType != linkTypes.None"
       [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
       [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]"
       class="card-img-top full-width">
      <div>
        <ds-themed-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
        </ds-themed-thumbnail>
      </div>
    </a>
    <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
                <div>
                    <ds-themed-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
                    </ds-themed-thumbnail>
                </div>
            </span>
    <div class="card-body">
      <ds-themed-badges *ngIf="showLabel" [object]="dso"></ds-themed-badges>
      <ds-truncatable-part [id]="dso.id" [minLines]="3" type="h4">
        <h4 class="card-title" [innerHTML]="dsoTitle"></h4>
      </ds-truncatable-part>
      <p *ngIf="dso.hasMetadata('creativework.datePublished')"
         class="item-date card-text text-muted">
        <ds-truncatable-part [id]="dso.id" [minLines]="1">
          <span [innerHTML]="firstMetadataValue('creativework.datePublished')"></span>
        </ds-truncatable-part>
      </p>
      <p *ngIf="dso.hasMetadata('dc.description')" class="item-description card-text">
        <ds-truncatable-part [id]="dso.id" [minLines]="3">
          <span [innerHTML]="firstMetadataValue('dc.description')"></span>
        </ds-truncatable-part>
      </p>
      <div *ngIf="linkType != linkTypes.None" class="text-center">
        <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
           [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" [routerLink]="[itemPageRoute]"
           class="lead btn btn-primary viewButton">View</a>
      </div>
    </div>
  </ds-truncatable>
  <ng-content></ng-content>
</div>
