/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  BaseChartComponent,
  calculateViewDimensions,
  ColorHelper, escapeLabel,
  formatLabel,
  ViewDimensions
} from '@swimlane/ngx-charts';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild, ElementRef,
  EventEmitter,
  Input,
  Output, TemplateRef, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { scaleLinear } from 'd3-scale';
import { isNotEmpty } from '../../../../../../../../../app/shared/empty.util';

@Component({
  selector: 'ds-ngx-custom-gauge-inline',
  templateUrl: './ngx-custom-gauge-inline.component.html',
  styleUrls: ['./ngx-custom-gauge-inline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomGaugeInlineComponent extends BaseChartComponent {
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition = 'right';
  @Input() min = 0;
  @Input() max = 100;
  @Input() textValue: string;
  @Input() bigSegments = 10;
  @Input() smallSegments = 5;
  @Input() results: any[];
  @Input() showAxis = true;
  @Input() startAngle = -120;
  @Input() angleSpan = 240;
  @Input() activeEntries: any[] = [];
  @Input() axisTickFormatting: any;
  @Input() tooltipDisabled = false;
  @Input() valueFormatting: (value: any) => string;
  @Input() showText = true;
  @Input() backgroundArcColor = 'rgb(185,185,185)';
  @Input() gaugeLabel: string;

  // Specify margins
  @Input() margin: any[];

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  @ViewChild('textEl') textEl: ElementRef;
  @ViewChild('labelEl') labelEl: ElementRef;

  dims: ViewDimensions;
  domain: any[];
  valueDomain: any;
  valueScale: any;

  colors: ColorHelper;
  transform: string;

  outerRadius: number;
  textRadius: number; // max available radius for the text
  resizeScale = 1;
  resizeLabelScale = 1;
  rotation = '';
  textTransform = 'scale(1, 1)';
  labelTransform = 'scale(1, 1)';
  cornerRadius = 10;
  arcs: any[];
  displayValue: string;
  legendOptions: any;
  label: string;

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    setTimeout(() => this.scaleText());
  }

  update(): void {
    super.update();

    this.margin = [0, 0, 0, 0];

    // make the starting angle positive
    if (this.startAngle < 0) {
      this.startAngle = (this.startAngle % 360) + 360;
    }

    this.angleSpan = Math.min(this.angleSpan, 360);

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showLegend: this.legend,
      legendPosition: this.legendPosition
    });

    this.domain = this.getDomain();
    this.valueDomain = this.getValueDomain();
    this.valueScale = this.getValueScale();
    this.displayValue = this.getDisplayValue();
    this.label = this.getLabel();

    this.outerRadius = Math.min(this.dims.width, this.dims.height) / 2;

    this.arcs = this.getArcs();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    const xOffset = this.margin[3] + this.dims.width / 2;
    const yOffset = this.margin[0] + this.dims.height / 2;

    this.transform = `translate(${xOffset}, ${yOffset})`;
    this.rotation = `rotate(${this.startAngle})`;
    setTimeout(() => this.scaleText(), 50);
    setTimeout(() => this.scaleLabelText(), 50);
  }

  getArcs(): any[] {
    const arcs = [];

    const availableRadius = this.outerRadius * 0.7;

    const radiusPerArc = Math.min(availableRadius, 10);
    const arcWidth = radiusPerArc * 0.7;
    this.textRadius = (this.outerRadius - radiusPerArc) * 1.5;
    this.cornerRadius = Math.floor(arcWidth / 2);

    const i = 0;
    const d = this.results[0];
    const outerRadius = this.outerRadius - i * radiusPerArc;
    const innerRadius = outerRadius - arcWidth;

    const backgroundArc = {
      endAngle: (this.angleSpan * Math.PI) / 180,
      innerRadius,
      outerRadius,
      data: {
        value: this.max,
        name: d.name
      }
    };

    const valueArc = {
      endAngle: (Math.min(this.valueScale(d.value), this.angleSpan) * Math.PI) / 180,
      innerRadius,
      outerRadius,
      data: {
        value: d.value,
        name: d.name
      }
    };

    const arc = {
      backgroundArc,
      valueArc
    };

    arcs.push(arc);

    return arcs;
  }

  getDomain(): any[] {
    return this.results.map(d => d.name);
  }

  getValueDomain(): any[] {
    const values = this.results.map(d => d.value);
    const dataMin = Math.min(...values);
    const dataMax = Math.max(...values);

    if (this.min !== undefined) {
      this.min = Math.min(this.min, dataMin);
    } else {
      this.min = dataMin;
    }

    if (this.max !== undefined) {
      this.max = Math.max(this.max, dataMax);
    } else {
      this.max = dataMax;
    }

    return [this.min, this.max];
  }

  getValueScale(): any {
    return scaleLinear().range([0, this.angleSpan]).nice().domain(this.valueDomain);
  }

  getDisplayValue(): string {
    const value = this.max;

    if (this.textValue && 0 !== this.textValue.length) {
      return this.textValue.toLocaleString();
    }

    if (this.valueFormatting) {
      return this.valueFormatting(value);
    }

    return value.toLocaleString();
  }

  getLabel(): string {
    return isNotEmpty(this.gaugeLabel) ? this.gaugeLabel : this.results[0].name;
  }

  scaleText(repeat: boolean = true): void {
    if (!this.showText) {
      return;
    }
    const { width } = this.textEl.nativeElement.getBoundingClientRect();
    const oldScale = this.resizeScale;

    if (width === 0) {
      this.resizeScale = 1;
    } else {
      const availableSpace = this.textRadius;
      this.resizeScale = Math.floor((availableSpace / (width / this.resizeScale)) * 100) / 100;
    }

    // Cap out the resize scale to 2.4 to prevent values from overflowing out of the graph
    if (this.resizeScale > 2.4) {
      this.resizeScale = 2.4;
    }

    if (this.resizeScale !== oldScale) {
      this.textTransform = `scale(${this.resizeScale}, ${this.resizeScale})`;
      this.cd.markForCheck();
      if (repeat) {
        setTimeout(() => this.scaleText(false), 50);
      }
    }
  }

  scaleLabelText(repeat: boolean = true): void {
    if (!this.showText) {
      return;
    }
    const { width } = this.labelEl.nativeElement.getBoundingClientRect();
    const oldLabelScale = this.resizeLabelScale;

    if (width === 0) {
      this.resizeLabelScale = 1;
    } else {
      const availableSpace = this.textRadius;
      this.resizeLabelScale = Math.floor((availableSpace / (width / this.resizeLabelScale)) * 100) / 100;
    }

    if (this.resizeLabelScale !== oldLabelScale) {
      this.labelTransform = `scale(${this.resizeLabelScale}, ${this.resizeLabelScale})`;
      this.cd.markForCheck();
      if (repeat) {
        setTimeout(() => this.scaleLabelText(false), 50);
      }
    }
  }

  onClick(data): void {
    this.select.emit(data);
  }

  getLegendOptions(): any {
    return {
      scaleType: 'ordinal',
      colors: this.colors,
      domain: this.domain,
      title: this.legendTitle,
      position: this.legendPosition
    };
  }

  setColors(): void {
    this.colors = new ColorHelper(this.scheme, 'ordinal', this.domain, this.customColors);
  }

  onActivate(item): void {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item): void {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  isActive(entry): boolean {
    if (!this.activeEntries) { return false; }
    const item = this.activeEntries.find(d => {
      return entry.name === d.name && entry.series === d.series;
    });
    return item !== undefined;
  }

  trackBy(index, item): string {
    return item.valueArc.data.name;
  }

  tooltipText(arc): string {
    const label = formatLabel(arc.data.name);
    let val;

    if (this.valueFormatting) {
      val = this.valueFormatting(arc.data.value);
    } else {
      val = formatLabel(arc.data.value);
    }

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">${val}</span>
    `;
  }
}
