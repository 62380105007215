<div class="col-3 float-left d-flex h-100 action-label">
      <span class="justify-content-center align-self-center">
        {{'item.edit.tabs.status.buttons.' + operation.operationKey + '.label' | translate}}
      </span>
</div>
<div class="col-9 float-left action-button">
  <span *ngIf="operation.authorized">
    <button class="btn btn-outline-primary" [disabled]="operation.disabled" [routerLink]="operation.operationUrl">
      {{'item.edit.tabs.status.buttons.' + operation.operationKey + '.button' | translate}}
    </button>
  </span>
  <span *ngIf="!operation.authorized" [ngbTooltip]="'item.edit.tabs.status.buttons.unauthorized' | translate">
    <button class="btn btn-outline-primary" [disabled]="true">
      {{'item.edit.tabs.status.buttons.' + operation.operationKey + '.button' | translate}}
    </button>
  </span>
</div>
