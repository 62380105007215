import { Component, Input } from '@angular/core';
import { ThemedComponent } from '../../../../shared/theme-support/themed.component';
import { ItemEditBitstreamNameComponent } from './item-edit-bitstream-name.component';
import { Bitstream } from '../../../../core/shared/bitstream.model';

/**
 * Component displaying a bitstream name on the item-edit-bitstream page.
 */
@Component({
  selector: 'ds-themed-item-edit-bitstream-name',
  styleUrls: [],
  templateUrl: '../../../../shared/theme-support/themed.component.html',
})
export class ThemedItemEditBitstreamNameComponent extends ThemedComponent<ItemEditBitstreamNameComponent> {

  @Input() bitstream: Bitstream;

  protected inAndOutputNames: (keyof ItemEditBitstreamNameComponent & keyof this)[] = [
    'bitstream',
  ];

  protected getComponentName(): string {
    return 'ItemEditBitstreamNameComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../themes/${themeName}/app/item-page/edit-item-page/item-bitstreams/item-edit-bitstream-name/item-edit-bitstream-name.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./item-edit-bitstream-name.component`);
  }
}
