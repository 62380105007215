/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { AtmireAlertComponent } from './alert/atmire-alert.component';
import { AtmirePageWithSidebarComponent } from './sidebar/atmire-page-with-sidebar.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { MultiFacetValueSelectorComponent } from './facet-value-selector/multi-facet-value-selector/multi-facet-value-selector.component';
import { StatisticsMenuService } from './menu/statistics-menu/statistics-menu.service';
import { ExportAsImageDirective } from './utils/export-as-image/export-as-image.directive';
import { ExportAsImageTriggerDirective } from './utils/export-as-image/export-as-image-trigger/export-as-image-trigger.directive';
import { ExportAsImageDomDirective } from './utils/export-as-image/export-as-image-dom/export-as-image-dom.directive';
import { ExportAsImageModalComponent } from './utils/export-as-image/export-as-image-modal/export-as-image-modal.component';
import { DsPercentPipe } from './utils/ds-percent.pipe';
import { DsDecimalPipe } from './utils/ds-decimal-pipe';
import { InputFocusDirective } from './utils/input-autofocus.directive';
import { StatletDataService } from '../atmire-cua/statlets/shared/data/services/statlet-data.service';
import { AtmireSidebarService } from './sidebar/atmire-sidebar.service';
import { CollapsibleCardComponent } from './collapsible-card/collapsible-card.component';
import { DsoSelectorInputComponent } from './dso-selector/dso-selector-input/dso-selector-input.component';
import { MultiDsoSelectorInputComponent } from './dso-selector/multi-dso-selector-input/multi-dso-selector-input.component';
import { DsoSelectorService } from './dso-selector/services/dso-selector.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';

const DECLARATIONS = [
  AtmireAlertComponent,
  AtmirePageWithSidebarComponent,
  CollapsibleCardComponent,
  DsoSelectorInputComponent,
  MultiDsoSelectorInputComponent,
  DateRangePickerComponent,
  MultiFacetValueSelectorComponent,
  ExportAsImageDirective,
  ExportAsImageTriggerDirective,
  ExportAsImageDomDirective,
  ExportAsImageModalComponent,
  InputFocusDirective,
];

const PROVIDERS = [
  StatisticsMenuService,
  StatletDataService,
  AtmireSidebarService,
  DsoSelectorService,
];

const PIPES = [
  DsPercentPipe,
  DsDecimalPipe,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
    ...PIPES,
  ],
  providers: [
    ...PROVIDERS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule,
    NgSelectModule,
  ],
  exports: [
    ...DECLARATIONS,
    ...PIPES,
  ]
})
/**
 * A module for shared components/services between other atmire modules
 * Import this module in an atmire module to get access to its contents
 */
export class AtmireSharedModule {
}
