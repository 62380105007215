/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from './models/date-range.model';
import { LAST_MONTH, LAST_QUARTER, LAST_WEEK, LAST_YEAR, THIS_MONTH, THIS_QUARTER, THIS_WEEK, THIS_YEAR, TODAY, YESTERDAY } from './models/relative-date-range.model';
import { ALL_TIME } from './models/absolute-date-range.model';
import * as moment from 'moment';
import { hasNoValue, hasValue } from '../../../app/shared/empty.util';
import { BehaviorSubject } from 'rxjs';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';

export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
const STANDARD_DATE_REGEX = '(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))';

@Component({
  selector: 'ds-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() presets: DateRange[] = [ALL_TIME, TODAY, YESTERDAY, THIS_WEEK, LAST_WEEK, THIS_MONTH, LAST_MONTH, THIS_QUARTER, LAST_QUARTER, THIS_YEAR, LAST_YEAR];
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() presetName: string;
  @Input() placeholder: string;
  @Input() alignment: 'left' | 'right' = 'left';
  @Input() presetsOnly = false;
  @Input() dateFormat = STANDARD_DATE_FORMAT;
  @Input() minMode: BsDatepickerViewMode = 'day';
  @Output() onChange: EventEmitter<DateRange> = new EventEmitter();

  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedPresetName: string;
  disabled$: BehaviorSubject<boolean>;
  dateRegex = STANDARD_DATE_REGEX;

  ngOnInit(): void {
    if (hasValue(this.presetName) && this.presetName !== ALL_TIME.name) {
      const selectedPreset = this.presets.find((preset) => preset.name === this.presetName);
      this.startDate = selectedPreset.startDate;
      this.endDate = selectedPreset.endDate;
      this.onChange.emit(this.getValue());
    }
    this.disabled$ = new BehaviorSubject(this.isCalendarDisabled());
  }

  open() {
    this.selectedStartDate = this.startDate;
    this.selectedEndDate = this.endDate;
    this.selectedPresetName = this.presetName;
    this.disabled$.next(this.isCalendarDisabled());
  }

  setToCustom() {
    this.selectedPresetName = undefined;
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
  }

  updateWithPreset(preset: DateRange) {
    this.disabled$.next(this.isCalendarDisabled());
    this.selectedPresetName = preset.name;
    this.selectedStartDate = preset.startDate;
    this.selectedEndDate = preset.endDate;
  }

  updateRange(dates: Date[]) {
    if (dates.length > 1 && dates.every((v) => hasValue(v))) {
      if ((!this.selectedStartDate || this.selectedStartDate.valueOf() !== dates[0].valueOf()) || (!this.selectedEndDate || this.selectedEndDate.valueOf() !== dates[1].valueOf())) {
        this.selectedStartDate = dates[0];
        this.selectedEndDate = dates[1];
        this.selectedPresetName = undefined;
      }
    }
  }

  public getValue(): DateRange {
    return { startDate: this.startDate, endDate: this.endDate, name: this.presetName };
  }

  applyChanges(): void {
    const startDate = moment(this.selectedEndDate);
    const endDate = moment(this.selectedEndDate);
    const validDates = startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate);
    const allTimeDates = hasNoValue(this.selectedStartDate) && hasNoValue(this.selectedEndDate);
    if (validDates || allTimeDates) {
      this.startDate = this.selectedStartDate;
      this.endDate = this.selectedEndDate;
      this.presetName = this.selectedPresetName;
      this.onChange.emit(this.getValue());
    }
  }

  get dateRangeString(): string {
    if (hasValue(this.startDate) && hasValue(this.endDate)) {
      const startString = this.dateAsString(this.startDate);
      const endString = this.dateAsString(this.endDate);
      return startString === endString ? startString : startString + ' - ' + endString;
    } else if (hasNoValue(this.startDate) && hasNoValue(this.endDate)) {
      return '';
    } else {
      return hasValue(this.startDate) ? this.dateAsString(this.startDate) : this.dateAsString(this.endDate);
    }
  }

  get selectedStartDateAsString(): string {
    return this.dateAsString(this.selectedStartDate);
  }

  set selectedStartDateAsString(dateString: string) {
    if (dateString !== this.selectedStartDateAsString) {
      const startDate = moment(dateString).startOf('day');
      const endDate = moment(this.selectedEndDate);
      if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
        this.selectedEndDate = null;
      }
      this.selectedStartDate = startDate.toDate();
      this.selectedPresetName = undefined;
    }
  }

  get selectedEndDateAsString(): string {
    return this.dateAsString(this.selectedEndDate);
  }

  set selectedEndDateAsString(dateString: string) {
    if (dateString !== this.selectedEndDateAsString) {
      const startDate = moment(this.selectedStartDate);
      const endDate = moment(dateString).endOf('day');
      if (startDate.isValid() && endDate.isValid() && endDate.isBefore(startDate)) {
        this.selectedStartDate = null;
      }
      this.selectedEndDate = endDate.toDate();
      this.selectedPresetName = undefined;
    }
  }

  private dateAsString(date: Date) {
    return hasValue(date) ? moment(date).format(this.dateFormat) : '';
  }

  isCalendarDisabled() {
    return this.presetsOnly;
  }
}
