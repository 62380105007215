/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { LinkService } from '../../../../app/core/cache/builders/link.service';
import { Injectable, Injector } from '@angular/core';
import { HALResource } from '../../../../app/core/shared/hal-resource.model';
import { FollowLinkConfig } from '../../../../app/shared/utils/follow-link-config.model';
import { EMPTY, Observable } from 'rxjs';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { hasNoValue, hasValue } from '../../../../app/shared/empty.util';
import { addProjectionToHref } from '../../../shared/utils/projection.model';

@Injectable({
  providedIn: 'root'
})
export class AtmireLinkService extends LinkService {
  public resolveLinkWithoutAttaching<T extends HALResource, U extends HALResource>(model, linkToFollow: FollowLinkConfig<T>): Observable<RemoteData<U>> {
    const matchingLinkDef = this.getLinkDefinition(model.constructor, linkToFollow.name);

    if (hasValue(matchingLinkDef)) {
      const provider = this.getDataServiceFor(matchingLinkDef.resourceType);

      if (hasNoValue(provider)) {
        throw new Error(`The @link() for ${linkToFollow.name} on ${model.constructor.name} models uses the resource type ${matchingLinkDef.resourceType.value.toUpperCase()}, but there is no service with an @dataService(${matchingLinkDef.resourceType.value.toUpperCase()}) annotation in order to retrieve it`);
      }

      const service = Injector.create({
        providers: [],
        parent: this.parentInjector
      }).get(provider);

      const link = model._links[matchingLinkDef.linkName];
      if (hasValue(link)) {
        let href = link.href;
        if (hasValue(linkToFollow.projection)) {
          href = addProjectionToHref(href, linkToFollow.projection);
        }

        try {
          if (matchingLinkDef.isList) {
            return service.findAllByHref(href, linkToFollow.findListOptions, linkToFollow.useCachedVersionIfAvailable, linkToFollow.reRequestOnStale, ...linkToFollow.linksToFollow);
          } else {
            return service.findByHref(href, linkToFollow.useCachedVersionIfAvailable, linkToFollow.reRequestOnStale, ...linkToFollow.linksToFollow);
          }
        } catch (e) {
          console.error(`Something went wrong when using @dataService(${matchingLinkDef.resourceType.value}) ${hasValue(service) ? '' : '(undefined) '}to resolve link ${linkToFollow.name} at ${href}`);
          throw e;
        }
      }
    } else if (!linkToFollow.isOptional) {
      throw new Error(`followLink('${linkToFollow.name}') was used as a required link for a ${model.constructor.name}, but there is no property on ${model.constructor.name} models with an @link() for ${linkToFollow.name}`);
    }

    return EMPTY;
  }
}
