import { Component, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { AtmireSavedItemList } from '../../../../app-atmire/atmire-saved-item-list/models/atmire-saved-item-list.model';
import { AtmireSavedItemListDataService } from '../../../../app-atmire/atmire-saved-item-list/data-services/atmire-saved-item-list-data.service';
import { getFirstCompletedRemoteData } from '../../../core/shared/operators';
import { hasValue, isNotEmpty } from '../../empty.util';
import { map } from 'rxjs/operators';
import { NotificationsService } from '../../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ds-atmire-saved-item-list-create-modal',
  templateUrl: 'atmire-saved-item-list-create-modal.component.html',
})
/**
 * A modal to create a new {@link AtmireSavedItemList} where the user can enter a name
 */
export class AtmireSavedItemListCreateModalComponent {
  @Output() confirm: Subject<AtmireSavedItemList> = new Subject();
  @Output() cancel: Subject<boolean> = new Subject();
  @Output() error: Subject<string> = new Subject();

  name = '';
  exists = false;

  constructor(protected activeModal: NgbActiveModal,
              protected savedItemListService: AtmireSavedItemListDataService,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService) {
  }

  onSubmit() {
    if (isNotEmpty(this.name)) {
      this.listExists().subscribe((exists) => {
        if (exists) {
          this.notificationsService.error(this.translate.instant('atmire.saved-item-list.new-list.name.exists'));
        } else {
          this.savedItemListService.createByName(this.name).pipe(
            getFirstCompletedRemoteData()
          ).subscribe((rd) => {
            if (rd.hasFailed) {
              this.error.next(rd.errorMessage);
            } else if (rd.hasSucceeded) {
              this.confirm.next(rd.payload);
              this.activeModal.close();
            }
          });
        }
      });
    }
  }

  onCancel() {
    this.cancel.next(true);
    this.activeModal.close();
  }

  checkName() {
    if (isNotEmpty(this.name)) {
      this.listExists().subscribe((listExists) => {
        this.exists = listExists;
      });
    } else {
      this.exists = false;
    }
  }

  private listExists(): Observable<boolean> {
    return this.savedItemListService.searchByName(this.name).pipe(
      getFirstCompletedRemoteData(),
      map((rd) => rd.hasSucceeded && hasValue(rd.payload))
    );
  }
}
