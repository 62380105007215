<div>
  <div class="modal-header">{{ 'atmire.saved-item-list.new-list.header' | translate }}
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'atmire.saved-item-list.new-list.info' | translate }}</p>
    <form #form="ngForm" (ngSubmit)="onSubmit()">
      <div class="form-group mb-0">
        <input type="text" class="form-control" [ngClass]="{ 'is-invalid': exists || !name || name.length === 0 }" [(ngModel)]="name" [dsDebounce]="300" (onDebounce)="checkName()" name="name" />
        <div class="invalid-feedback" *ngIf="exists">{{ 'atmire.saved-item-list.new-list.name.exists' | translate }}</div>
        <div class="invalid-feedback" *ngIf="!name || name.length === 0">{{ 'atmire.saved-item-list.new-list.name.empty' | translate }}</div>
        <div class="d-flex mt-3">
          <button type="button" class="cancel btn btn-secondary mr-auto" (click)="onCancel()" aria-label="Cancel">
            {{ 'atmire.saved-item-list.new-list.cancel' | translate }}
          </button>
          <button type="submit" class="confirm btn btn-primary" [disabled]="exists || !name || name.length === 0" aria-label="Confirm" ngbAutofocus>
            {{ 'atmire.saved-item-list.new-list.confirm' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
