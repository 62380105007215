/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss']
})
/**
 * A component displaying summary information about a StorageReport
 */
export class ReportSummaryComponent {
  /**
   * The text of the header to display (supports i18n keys)
   */
  @Input() header: string;

  /**
   * Optional link pair for displaying a link next to the header between parentheses
   * - label    Text to display (supports i18n keys)
   * - route    Route to link to
   */
  @Input() headerLinkPair: {
    label: string,
    route: string,
    queryParams?: any,
  };

  /**
   * List of value pairs containing summary information about the StorageReport
   * - label    Text to display (supports i18n keys)
   * - value    Text to display next to the label (after a colon)
   */
  @Input() valuePairs: {
    label: string,
    value: string
  }[];

}
