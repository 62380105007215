/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { WorkflowItemSearchResultListElementComponent as BaseComponent } from '../../../../../../../app/shared/object-list/my-dspace-result-list-element/workflow-item-search-result/workflow-item-search-result-list-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { WorkflowItemSearchResult } from '../../../../../../../app/shared/object-collection/shared/workflow-item-search-result.model';
import { TruncatableService } from '../../../../../../../app/shared/truncatable/truncatable.service';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { AtmireSavedItemListStoreService } from '../../../../../../../app-atmire/atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { DSONameService } from '../../../../../../../app/core/breadcrumbs/dso-name.service';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { createProjection } from '../../../../../../../app-atmire/shared/utils/projection.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { Item } from '../../../../../../../app/core/shared/item.model';

@Component({
  selector: 'ds-workflow-item-my-dspace-result-list-element',
  styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss'],
  templateUrl: './workflow-item-search-result-list-element.component.html'
})
@listableObjectComponent(WorkflowItemSearchResult, ViewMode.ListElement, undefined, 'atmire')
export class WorkflowItemSearchResultListElementComponent extends BaseComponent {
  constructor(
    protected truncatableService: TruncatableService,
    protected linkService: LinkService,
    protected atmireSavedItemListStoreService: AtmireSavedItemListStoreService,
    protected dsoNameService: DSONameService
  ) {
    super(truncatableService, linkService, dsoNameService);
  }

  /**
   * Initialize all instance variables
   */
  ngOnInit() {
    super.ngOnInit();
    this.linkService.resolveLink(this.dso, followLink('item', { projection: createProjection('SavedItemLists', 'savedList', this.atmireSavedItemListStoreService.getStoredListByContext(this.context)) } ));
    this.initItem(this.dso.item as Observable<RemoteData<Item>> );
  }
}
