/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { StatletDefaultDataTypeService } from './statlet-default-data-type.service';
import { Injectable } from '@angular/core';
import { STATLET_HEADER_ANY, statletDataTypeService } from './statlet-data-type.decorator';
import { StatletPoint } from '../../data/models/statlet-point.model';
import { StatletPointRoute } from '../../data/models/statlet.utils';
import { Observable, of as observableOf } from 'rxjs';
import { getFirstCompletedRemoteData } from '../../../../../../app/core/shared/operators';
import { map } from 'rxjs/operators';
import { DSONameService } from '../../../../../../app/core/breadcrumbs/dso-name.service';
import { ItemDataService } from '../../../../../../app/core/data/item-data.service';
import { getEntityPageRoute } from '../../../../../../app/item-page/item-page-routing-paths';

/**
 * {@link StatletDefaultDataTypeService} for headers with category 'metadata' and ANY type. This is used by statlet's
 * which have mixed plain metadata headers, and DSO headers.
 */
@Injectable()
@statletDataTypeService('metadata', STATLET_HEADER_ANY)
export class StatletMetadataDataTypeService extends StatletDefaultDataTypeService {

  constructor(public dsoNameService: DSONameService, public itemDataService: ItemDataService) {
    super();
  }

  uuidRegex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g;

  /**
   * If the given value is UUID => Resolve it to an item and gets its name from {@link DSONameService}
   * Otherwise the original value is just returned (just a plain metadata, not referencing an item)
   * @param value   Value to transform to possible alternate value to display in statlet table
   */
  transform(value: string): Observable<string> {
    if (value.match(this.uuidRegex)) {
      return this.itemDataService.findById(value).pipe(
        getFirstCompletedRemoteData(),
        map((rd) => {
          if (rd.hasSucceeded) {
            return this.dsoNameService.getName(rd.payload);
          } else {
            return value;
          }
        })
      );
    } else {
      return observableOf(value);
    }
  }

  /**
   * If the value is a UUID => The route will point to the corresponding item page
   * Otherwise there is no route given (table header will not link anywhere)
   * @param value   Value to get a statlet point route for
   */
  getLinkByValue(value: string): StatletPointRoute {
    if (value.match(this.uuidRegex)) {
      return {href: getEntityPageRoute(null, value)};
    } else {
      return null;
    }
  }

  /**
   * If the point's id is a UUID => The route will point to the corresponding item page
   * Otherwise there is no route given (table header will not link anywhere)
   * @param point   Statlet point to get a possible rout for
   */
  getLinkByPoint(point: StatletPoint): StatletPointRoute {
    return this.getLinkByValue(point.id);
  }
}
