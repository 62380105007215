import { MODELS as APP_ATMIRE_MODELS } from '../app-atmire/atmire-app.models';
import { MODELS as APP_CLIENT_MODELS } from '../app-client/client-app.models';
import { models as CORE_MODELS } from './core/core.module';

/**
 * Declaration of ALL models needed to make sure all decorator functions are called in time
 * This is loaded into app component to ensure eager loading of all models
 */
export const MODELS = [
  ...CORE_MODELS,
  ...APP_ATMIRE_MODELS,
  ...APP_CLIENT_MODELS,
];
