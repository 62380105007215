/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ClaimedTaskSearchResultDetailElementComponent as BaseComponent } from '../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/claimed-task-search-result/claimed-task-search-result-detail-element.component';
import { Component, OnDestroy } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ClaimedTaskSearchResult } from '../../../../../../../app/shared/object-collection/shared/claimed-task-search-result.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { Observable, Subscription } from 'rxjs';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { AtmireSavedItemListStoreService } from '../../../../../../../app-atmire/atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { hasValue } from '../../../../../../../app/shared/empty.util';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { createProjection } from '../../../../../../../app-atmire/shared/utils/projection.model';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { WorkflowItem } from '../../../../../../../app/core/submission/models/workflowitem.model';

@Component({
  selector: 'ds-claimed-task-search-result-detail-element',
  styleUrls: ['../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/search-result-detail-element.component.scss'],
  templateUrl: '../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/claimed-task-search-result/claimed-task-search-result-detail-element.component.html'
})
@listableObjectComponent(ClaimedTaskSearchResult, ViewMode.DetailedListElement, undefined, 'atmire')
export class ClaimedTaskSearchResultDetailElementComponent extends BaseComponent implements OnDestroy {
  sub: Subscription;

  constructor(protected linkService: LinkService,
              protected atmireSavedItemListStoreService: AtmireSavedItemListStoreService
  ) {
    super(linkService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.sub = this.atmireSavedItemListStoreService.getStoredListIDObsByContext(this.context).pipe(
    ).subscribe((listID) => {
      if (hasValue(listID)) {
        this.linkService.resolveLinks(this.dso, followLink('workflowitem', {},
          followLink('item', { projection: createProjection('SavedItemLists', 'savedList', listID)}, followLink('bundles')),
          followLink('submitter')
        ), followLink('action'));
      } else {
        this.linkService.resolveLinks(this.dso, followLink('workflowitem', {},
          followLink('item', {}, followLink('bundles')),
          followLink('submitter')
        ), followLink('action'));
      }
    });
    this.workflowitemRD$ = this.dso.workflowitem as Observable<RemoteData<WorkflowItem>>;
  }

  ngOnDestroy(): void {
    if (hasValue(this.sub)) {
      this.sub.unsubscribe();
    }
  }

}
