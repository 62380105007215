/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DSpaceObjectType } from '../../../../app/core/shared/dspace-object-type.model';
import { SearchFilter } from '../../../../app/shared/search/models/search-filter.model';
import { CollectionElementLinkType } from '../../../../app/shared/object-collection/collection-element-link.type';
import { ViewMode } from '../../../../app/core/shared/view-mode.model';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { DsoSelectorOption } from '../models/dso-selector-option.model';
import { DsoSelectorService } from '../services/dso-selector.service';

@Component({
  selector: 'ds-multi-dso-selector-input',
  styleUrls: ['./multi-dso-selector-input.component.scss', '../input-shared/dso-selector-input-shared.scss'],
  templateUrl: './multi-dso-selector-input.component.html'
})
/**
 * A component rendering an input able to store multiple objects of certain types and the ability to dynamically search for them
 */
export class MultiDsoSelectorInputComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * The list of DSpaceObject types to search for
   */
  @Input() types: DSpaceObjectType[];

  /**
   * Search filters to apply to the search request
   */
  @Input() filters: SearchFilter[] = [];

  /**
   * A list of selected object IDs
   * These will be resolved into actual objects in order to retrieve their name
   */
  @Input() selectedIds: string[] = [];

  /**
   * Placeholder text to display
   */
  @Input() placeholder: string = null;

  /**
   * Whether or not the component should emit the selected IDs on change and not display any of the selected
   * objects within the input
   */
  @Input() emitOnChange = false;

  /**
   * Whether or not the list of IDs in the input should be cleared after a change occurs
   */
  @Input() clearOnChange = false;

  /**
   * Whether or not the component should emit the selected IDs when the list is set to empty
   */
  @Input() emitOnEmpty = true;

  /**
   * Render the input vertically
   */
  @Input() @HostBinding('class.vertical') vertical = false;

  /**
   * The vertical positioning of the input
   * Only applicable when vertical is set to true
   */
  @Input() @HostBinding('class') verticalPosition: 'bottom' | 'center' | 'top' = 'center';

  /**
   * Emits the list of selected IDs whenever the user adds or removes an object
   */
  @Output() submitIds: EventEmitter<string[]> = new EventEmitter();

  /**
   * The initially selected ids. Used to compare and check whether sending an update is necessary.
   */
  initialSelectedIds: string[] = [];

  /**
   * The query entered by the user
   */
  query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * A list of options containing objects to display in the dropdown
   */
  options$: BehaviorSubject<DsoSelectorOption[]> = new BehaviorSubject([]);

  /**
   * Whether or not data is loading
   * This will display a loading indicator when set to true
   */
  loading = false;

  /**
   * The available link types
   */
  linkTypes = CollectionElementLinkType;

  /**
   * The view mode of the listed objects
   */
  viewMode = ViewMode.ListElement;

  /**
   * List of subscriptions to unsubscribe from
   */
  private subs: Subscription[] = [];

  constructor(protected dsoSelectorService: DsoSelectorService) {
  }

  ngOnInit(): void {
    this.initialSelectedIds = this.selectedIds;
    this.updateOptionsFromQuery();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasValue(changes.filters)) {
      this.ngOnDestroy();
      this.subs = [];
      this.updateOptionsFromQuery();
    }
  }

  /**
   * Subscribe to the query and update the options accordingly
   */
  updateOptionsFromQuery() {
    this.subs.push(
      // Update the list of options to display in the dropdown depending on the current query entered by the user
      this.query$.pipe(
        switchMap((query) => {
          this.loading = true;
          return this.dsoSelectorService.getOptions(query, this.types, this.filters, ...this.selectedIds);
        }),
      ).subscribe((options: DsoSelectorOption[]) => {
        this.loading = false;
        this.options$.next(options);
      }),
    );
  }

  /**
   * Send an update to the parent component
   * Only send an update if the ids are different from the initially selected ids
   * @param ids
   */
  submit(ids: string[]) {
    if (!this.idsEqualInitial(ids) && (isNotEmpty(ids) || this.emitOnEmpty)) {
      this.submitIds.emit(ids);
    }
  }

  /**
   * The list of selected IDs changed
   * emit if emitOnAdd is true
   */
  change() {
    if (this.emitOnChange && (isNotEmpty(this.selectedIds) || this.emitOnEmpty)) {
      this.submitIds.emit(this.selectedIds);
    }
    if (this.clearOnChange) {
      this.selectedIds = [];
    }
    this.reloadList();
  }

  /**
   * Reload the list by emitting the current query again
   * This is used to update the list of options whenever selectedIds updates
   */
  reloadList() {
    this.query$.next(this.query$.value);
  }

  /**
   * Method to check if a list of IDs match the list of initially selected IDs
   * @param ids
   */
  idsEqualInitial(ids: string[]): boolean {
    return ids.length === this.initialSelectedIds.length &&
      ids.every((e) => this.initialSelectedIds.includes(e)) &&
      this.initialSelectedIds.every((e) => ids.includes(e));
  }

  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
