<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="map-chart">
  <ds-atmire-cua-world-heat-map *ngIf="(data$ | async) && (labels$ | async)"
    [id]="id" [minColour]="minColour" [maxColour]="maxColour"
    [labels]="labels$ | async"
    [data]="data$ | async">
  </ds-atmire-cua-world-heat-map>
</div>
