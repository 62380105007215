<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="w-100 d-inline-block">
  <h2 class="float-left" *ngIf="header">
    {{ header | translate }}
    <small *ngIf="headerLinkPair" class="very-small">(<a [routerLink]="[headerLinkPair.route]" [queryParams]="headerLinkPair.queryParams">{{ headerLinkPair.label | translate }}</a>)</small>
  </h2>
</div>
<div *ngFor="let valuePair of valuePairs" class="storage-report-summary-field">
  <span class="font-weight-bold">{{ valuePair.label | translate }}</span>
  <span>: </span>
  <span>{{ valuePair.value }}</span>
</div>
