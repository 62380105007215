/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { StatletDataTypeService } from './statlet-data-type.service';
import { Observable, of as observableOf } from 'rxjs';
import { STATLET_HEADER_ANY, statletDataTypeService } from './statlet-data-type.decorator';
import { Injectable } from '@angular/core';
import { StatletPoint } from '../../data/models/statlet-point.model';
import { StatletPointRoute } from '../../data/models/statlet.utils';

@Injectable()
@statletDataTypeService(STATLET_HEADER_ANY, STATLET_HEADER_ANY)
export class StatletDefaultDataTypeService extends StatletDataTypeService {
  transform(value: string): Observable<string> {
    return observableOf(value);
  }

  getLinkByPoint(point: StatletPoint): StatletPointRoute {
    return null;
  }

  getLinkByValue(value: string): StatletPointRoute {
    return null;
  }
}
