<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="item" @fadeInOut>
  <ds-themed-badges [object]="item" [myDSpaceStatus]="status"></ds-themed-badges>
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <ds-truncatable [id]="item.id">
        <h3 [innerHTML]="dsoTitle" [ngClass]="{'lead': true,'text-muted': !item.firstMetadataValue('dc.title')}"></h3>
        <div>
      <span class="text-muted">
        <ds-truncatable-part [id]="item.id" [minLines]="1">
          (<span *ngIf="item.hasMetadata('dc.publisher')" class="item-list-publisher"
                 [innerHTML]="item.firstMetadataValue('dc.publisher') + ', '"></span>
          <span class="item-list-date" [innerHTML]="item.firstMetadataValue('dc.date.issued') || ('mydspace.results.no-date' | translate)"></span>)
          <span *ngIf="item.hasMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);"
                class="item-list-authors">
              <span *ngIf="item.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length === 0">{{'mydspace.results.no-authors' | translate}}</span>
              <span *ngFor="let author of item.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
              </span>
          </span>

          </ds-truncatable-part>
      </span>

          <ds-truncatable-part [id]="item.id" [minLines]="1" class="item-list-abstract">
        <span [ngClass]="{'text-muted': !item.firstMetadataValue('dc.description.abstract')}"
              [innerHTML]="(item.firstMetadataValue('dc.description.abstract')) || ('mydspace.results.no-abstract' | translate)"></span>
          </ds-truncatable-part>

        </div>
      </ds-truncatable>
    </div>
    <div class="ml-2">
      <ds-atmire-saved-item-list-control [item]="item" [context]="context"></ds-atmire-saved-item-list-control>
    </div>
  </div>
  <ds-item-submitter *ngIf="showSubmitter" [object]="object.indexableObject"></ds-item-submitter>
</div>
