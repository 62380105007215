/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Statlet } from '../data/models/statlet.model';
import { hasStatletData } from '../data/models/statlet.utils';
import { Context } from '../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-atmire-cua-abstract-single-statlet-wrapper',
  template: ''
})
/**
 * Abstract component for displaying a single statlet within a wrapper
 * Components extending from this should use the @rendersSingleStatletWrapper annotation with the appropriate context
 */
export class SingleStatletWrapperComponent {
  /**
   * The statlet to display
   */
  @Input() statlet: Statlet;

  /**
   * The size the statlet is rendered in
   */
  @Input() size: string;

  @Input() context: Context;

  @Input() palette: string[];

  /**
   * Whether or not the statlet contains at least one point of data
   * Don't display the statlet if it doesn't contain any data
   */
  @Input() hasData = true;
}
