/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AtmireSelectiveExportOption } from '../../../models/atmire-selective-export-option.model';
import { invokeItemExportScriptByItem } from '../../../atmire-selective-export.util';
import { Item } from '../../../../../app/core/shared/item.model';
import { AbstractAtmireExportDropdownComponent } from '../abstract-atmire-export-dropdown.component';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { Process } from '../../../../../app/process-page/processes/process.model';

@Component({
  selector: 'ds-atmire-item-export-dropdown',
  templateUrl: './atmire-item-export-dropdown.component.html',
  styleUrls: ['../abstract-atmire-export-dropdown.component.scss']
})
/**
 * Component displaying a dropdown menu, listing export options for an item
 */
export class AtmireItemExportDropdownComponent extends AbstractAtmireExportDropdownComponent {
  /**
   * The item to export
   */
  @Input() item: Item;

  /**
   * Export items using the item ID and selected export type
   * @param exportOption  The export type to export items to
   */
  invokeScript(exportOption: AtmireSelectiveExportOption): Observable<RemoteData<Process>> {
    return invokeItemExportScriptByItem(this.authService, this.scriptDataService, exportOption, this.item);
  }

}
