/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AuthService } from '../../../app/core/auth/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EPerson } from '../../../app/core/eperson/models/eperson.model';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AtmireAuthService extends AuthService {
  /**
   * Returns the authenticated user from the store, or null if anonymous
   */
  public getAuthenticatedUserOrNullFromStore(): Observable<EPerson> {
    return this.isAuthenticated().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.getAuthenticatedUserFromStore();
        } else {
          return [null];
        }
      })
    );
  }
}
