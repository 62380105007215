/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PageWithSidebarComponent } from '../../../app/shared/sidebar/page-with-sidebar.component';
import { Component, Input } from '@angular/core';
import { pushInOut } from '../../../app/shared/animations/push';
import { HostWindowService, WidthCategory } from '../../../app/shared/host-window.service';
import { map } from 'rxjs/operators';
import { AtmireSidebarService } from './atmire-sidebar.service';

@Component({
  selector: 'ds-atmire-page-with-sidebar',
  styleUrls: ['./atmire-page-with-sidebar.component.scss'],
  templateUrl: './atmire-page-with-sidebar.component.html',
  animations: [pushInOut],
})
export class AtmirePageWithSidebarComponent extends PageWithSidebarComponent {
  /**
   * Whether or not the width of the entire component should be stretched to fit the window
   * If this is enabled, sideBarWith isn't used, because we use bootstrap flex for the stretched version
   */
  @Input() stretch = false;

  /**
   * The minimum size where the sidebar and contents are both visible
   * If the screen size gets smaller than the minimum width of this breakpoint, the sidebar will be collapsed
   */
  @Input() minExpandedSize: 'md' | 'lg' = 'md';

  constructor(protected sidebarService: AtmireSidebarService,
              protected windowService: HostWindowService,
  ) {
    super(sidebarService, windowService);
  }

  ngOnInit(): void {
    this.isXsOrSm$ = this.windowService.isXsOrSm();
    this.isSidebarCollapsed$ = this.minExpandedSize === 'lg' ? this.sidebarService.isCollapsedForBreakpoints([WidthCategory.XS, WidthCategory.SM, WidthCategory.MD]) : this.isSidebarCollapsed();
    this.sidebarClasses = this.isSidebarCollapsed$.pipe(
      map((isCollapsed) => isCollapsed ? '' : 'active')
    );
  }
}
