<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(workflowitemRD$ | async)?.payload as workflowitem">
  <ds-atmire-item-list-preview *ngIf="workflowitem"
                               [item]="(workflowitem?.item | async)?.payload"
                               [context]="context"
                               [object]="object"
                               [showSubmitter]="showSubmitter"
                               [status]="status"></ds-atmire-item-list-preview>
  <ds-claimed-task-actions *ngIf="workflowitem" [object]="dso" (processCompleted)="reloadedObject.emit($event.reloadedObject)"></ds-claimed-task-actions>
</ng-container>

