<ng-container *ngIf="item$ | async">
  <ds-themed-item-list-preview
    [item]="item$ | async"
    [object]="object"
    [status]="status"></ds-themed-item-list-preview>

  <ds-workflowitem-actions [object]="dso" (processCompleted)="reloadedObject.emit($event.reloadedObject)"></ds-workflowitem-actions>
</ng-container>
<ds-themed-loading
  *ngIf="!(item$ | async)"
  [showMessage]="false"></ds-themed-loading>
