/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { JournalVolumeSearchResultListElementComponent as BaseComponent } from '../../../../../../../../app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-volume/journal-volume-search-result-list-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';

@Component({
  selector: 'ds-journal-volume-search-result-list-element',
  styleUrls: ['../../../../../../../../app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-volume/journal-volume-search-result-list-element.component.scss'],
  templateUrl: './journal-volume-search-result-list-element.component.html'
})
@listableObjectComponent('JournalVolumeSearchResult', ViewMode.ListElement, undefined, 'atmire')
@listableObjectComponent('JournalVolume', ViewMode.ListElement, undefined, 'atmire')
export class JournalVolumeSearchResultListElementComponent extends BaseComponent {

}
