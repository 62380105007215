/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../app/shared/shared.module';
import { AtmireSavedItemListLinkComponent } from './atmire-saved-item-list-link/atmire-saved-item-list-link.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    AtmireSavedItemListLinkComponent,
  ],
  exports: [
    AtmireSavedItemListLinkComponent,
  ],
})
export class AtmireSavedItemListOtherModule {

}
