/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DateRange } from './date-range.model';

export class AbsoluteDateRange implements DateRange {
  constructor(public name: string,
              private _startTime,
              private _endTime) {
  }

  get startDate(): Date {
    return this._startTime;
  }

  set startDate(time: Date) {
    this._startTime = time;
  }

  get endDate(): Date {
    return this._endTime;
  }

  set endDate(time: Date) {
    this._endTime = time;
  }
}

export const ALL_TIME: DateRange = new AbsoluteDateRange('all_time', null, null);
