/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedSearchOptions } from '../../../../../app/shared/search/models/paginated-search-options.model';
import { AtmireSelectiveExportOption } from '../../../models/atmire-selective-export-option.model';
import { invokeItemExportScriptBySearchConfig } from '../../../atmire-selective-export.util';
import { AbstractAtmireExportDropdownComponent } from '../abstract-atmire-export-dropdown.component';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { Process } from '../../../../../app/process-page/processes/process.model';
import { ScriptDataService } from '../../../../../app/core/data/processes/script-data.service';
import { AuthService } from '../../../../../app/core/auth/auth.service';
import { AuthorizationDataService } from '../../../../../app/core/data/feature-authorization/authorization-data.service';
import { NotificationsService } from '../../../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { hasValue } from '../../../../../app/shared/empty.util';

@Component({
  selector: 'ds-atmire-selective-export-dropdown',
  templateUrl: './atmire-selective-export-dropdown.component.html',
  styleUrls: ['../abstract-atmire-export-dropdown.component.scss']
})
/**
 * Component displaying a dropdown menu, listing export options for a discovery search
 */
export class AtmireSelectiveExportDropdownComponent extends AbstractAtmireExportDropdownComponent {
  /**
   * The total number of results for the current search options
   */
  @Input() totalResults = -1;

  /**
   * The current configuration of the search
   */
  @Input() searchConfig: PaginatedSearchOptions;

  /**
   * THe prefix to use for i18n messages
   */
  @Input() msgPrefix = 'discovery';

  @Input() scope: DSpaceObject;

  constructor(protected scriptDataService: ScriptDataService,
              protected authService: AuthService,
              protected authorizationService: AuthorizationDataService,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected router: Router) {
    super(scriptDataService, authService, authorizationService, notificationsService, translate, router);
  }

  /**
   * Export items using the current discovery arguments and selected export type
   * @param exportOption  The export type to export items to
   */
  invokeScript(exportOption: AtmireSelectiveExportOption): Observable<RemoteData<Process>> {
    let searchConfigWithScope = this.searchConfig;
    if (hasValue(this.scope)) {
      searchConfigWithScope = Object.assign(new PaginatedSearchOptions({}), this.searchConfig, {scope: this.scope.uuid});
    }
    return invokeItemExportScriptBySearchConfig(this.authService, this.scriptDataService, exportOption, searchConfigWithScope);
  }

}
