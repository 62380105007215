<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)"
>
  <svg>
    <g [attr.transform]="transform" class="gauge chart">
      <g *ngFor="let arc of arcs; trackBy: trackBy" [attr.transform]="rotation">
        <g ngx-tooltip
           [tooltipDisabled]="tooltipDisabled"
           [tooltipPlacement]="'top'"
           [tooltipType]="'tooltip'"
           [tooltipTitle]="tooltipTemplate ? undefined : tooltipText(arc.valueArc)"
           [tooltipTemplate]="tooltipTemplate"
           [tooltipContext]="arc.valueArc.data">
          <g
            ngx-charts-pie-arc
            class="background-arc"
            [startAngle]="0"
            [endAngle]="arc.backgroundArc.endAngle"
            [innerRadius]="arc.backgroundArc.innerRadius"
            [outerRadius]="arc.backgroundArc.outerRadius"
            [cornerRadius]="cornerRadius"
            [data]="arc.backgroundArc.data"
            [animate]="false"
            [pointerEvents]="false"
            [fill]="backgroundArcColor"
          ></g>
          <g
            ngx-charts-pie-arc
            [startAngle]="0"
            [endAngle]="arc.valueArc.endAngle"
            [innerRadius]="arc.valueArc.innerRadius"
            [outerRadius]="arc.valueArc.outerRadius"
            [cornerRadius]="cornerRadius"
            [fill]="colors.getColor(arc.valueArc.data.name)"
            [data]="arc.valueArc.data"
            [animate]="animations"
            [isActive]="isActive"
            (select)="select.emit($event)"
            (activate)="activate.emit($event)"
            (deactivate)="deactivate.emit($event)"
          ></g>
        </g>
      </g>
      <text
        #textEl
        *ngIf="showText"
        [style.textAnchor]="'middle'"
        [attr.transform]="textTransform"
        alignment-baseline="central"
      >
        <tspan x="0" dy="0">{{ displayValue }}</tspan>
      </text>
      <text
        #labelEl
        *ngIf="showText"
        class="gauge-label"
        [style.textAnchor]="'middle'"
        alignment-baseline="central"
      >
        <tspan x="0" dy="1.2em">{{ label }}</tspan>
      </text>
    </g>
  </svg>
</ngx-charts-chart>
