/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SidebarService } from '../../../app/shared/sidebar/sidebar.service';
import { WidthCategory } from '../../../app/shared/host-window.service';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class AtmireSidebarService extends SidebarService {
  /**
   * Checks if the sidebar should currently be collapsed for a given range of widths
   * @param breakpoints
   */
  isCollapsedForBreakpoints(breakpoints: WidthCategory[]): Observable<boolean> {
    return observableCombineLatest(
      this.windowService.widthCategory.pipe(
        map((width) => breakpoints.some((breakpoint) => breakpoint === width))
      ),
      this.isCollapsedInStore
    ).pipe(
      map(([withinBreakpoints, store]) => withinBreakpoints ? store : true)
    );
  }
}
