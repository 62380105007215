/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { InjectionToken } from '@angular/core';
import { GenericConstructor } from '../../../../../../app/core/shared/generic-constructor';
import { hasNoValue } from '../../../../../../app/shared/empty.util';
import { hasValue } from '../../../../../../app/shared/empty.util';

const statletDataTypeServiceMap = new Map();

export const STATLET_HEADER_ANY = 'any';

export const STATLET_DATA_TYPE_SERVICE_FACTORY = new InjectionToken<(category: string, type: string, context: string) => GenericConstructor<any>>('getStatletDataTypeServiceFor', {
  providedIn: 'root',
  factory: () => getStatletDataTypeServiceFor
});

export function statletDataTypeService(category: string, type: string = STATLET_HEADER_ANY, context: string = STATLET_HEADER_ANY): any {
  return (target: any) => {
    if (hasNoValue(statletDataTypeServiceMap.get(category))) {
      statletDataTypeServiceMap.set(category, new Map());
    }
    if (hasNoValue(statletDataTypeServiceMap.get(category).get(context))) {
      statletDataTypeServiceMap.get(category).set(context, new Map());
    }
    if (!statletDataTypeServiceMap.get(category).get(context).has(type)) {
      statletDataTypeServiceMap.get(category).get(context).set(type, target);
    } else {
      throw new Error(`Multiple statlet-data-type-services for category \"${category}\", context \"${context}\" and type \"${type}\": ${statletDataTypeServiceMap.get(category).get(context).get(type)} and ${target}`);
    }
  };
}

export function getStatletDataTypeServiceFor(category: string, type: string, context: string) {
  let service;
  if (statletDataTypeServiceMap.has(category)) {
    if (statletDataTypeServiceMap.get(category).has(context)) {
      if (statletDataTypeServiceMap.get(category).get(context).has(type)) {
        service = statletDataTypeServiceMap.get(category).get(context).get(type);
      } else {
        service = statletDataTypeServiceMap.get(category).get(context).get(STATLET_HEADER_ANY);
      }
    } else {
      if (statletDataTypeServiceMap.get(category).get(STATLET_HEADER_ANY).has(type)) {
        service = statletDataTypeServiceMap.get(category).get(STATLET_HEADER_ANY).get(type);
      } else {
        service = statletDataTypeServiceMap.get(category).get(STATLET_HEADER_ANY).get(STATLET_HEADER_ANY);
      }
    }
  }
  if (hasValue(service)) {
    return service;
  } else {
    return statletDataTypeServiceMap.get(STATLET_HEADER_ANY).get(STATLET_HEADER_ANY).get(STATLET_HEADER_ANY);
  }
}
