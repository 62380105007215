<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(workflowitemRD$ | async)?.payload as workflowitem">
  <ds-atmire-item-list-preview *ngIf="workflowitem"
                               [item]="(workflowitem?.item | async)?.payload"
                               [context]="context"
                               [object]="object"
                               [showSubmitter]="showSubmitter"
                               [status]="status"></ds-atmire-item-list-preview>
  <div class="ml-2">
    <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="(workflowitem?.item | async)?.payload" [context]="context"></ds-atmire-saved-item-list-control>
  </div>

  <ds-pool-task-actions id="actions" *ngIf="workflowitem" [object]="dso" (processCompleted)="this.reloadedObject.emit($event.reloadedObject)"></ds-pool-task-actions>
</ng-container>

