/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ItemSearchResultGridElementComponent as BaseComponent } from '../../../../../../../../app/shared/object-grid/search-result-grid-element/item-search-result/item/item-search-result-grid-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResult } from '../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { focusShadow } from '../../../../../../../../app/shared/animations/focus';

@Component({
  selector: 'ds-item-search-result-grid-element',
  styleUrls: ['../../../../../../../../app/shared/object-grid/search-result-grid-element/item-search-result/item/item-search-result-grid-element.component.scss'],
  templateUrl: './item-search-result-grid-element.component.html',
  animations: [focusShadow]
})
@listableObjectComponent('PublicationSearchResult', ViewMode.GridElement, undefined, 'atmire')
@listableObjectComponent(ItemSearchResult, ViewMode.GridElement, undefined, 'atmire')
export class ItemSearchResultGridElementComponent extends BaseComponent {

}
