/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PersonSearchResultGridElementComponent as BaseComponent } from '../../../../../../../../app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/person/person-search-result-grid-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';

@Component({
  selector: 'ds-person-search-result-grid-element',
  styleUrls: ['../../../../../../../../app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/person/person-search-result-grid-element.component.scss'],
  templateUrl: './person-search-result-grid-element.component.html'
})
@listableObjectComponent('PersonSearchResult', ViewMode.GridElement, undefined, 'atmire')
@listableObjectComponent('Person', ViewMode.GridElement, undefined, 'atmire')
export class PersonSearchResultGridElementComponent extends BaseComponent {

}
