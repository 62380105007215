/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { StatletDsoDataTypeService } from './statlet-dso-data-type.service';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { DSONameService } from '../../../../../../app/core/breadcrumbs/dso-name.service';
import { Injectable } from '@angular/core';
import { CollectionDataService } from '../../../../../../app/core/data/collection-data.service';
import { StatletPointRoute } from '../../data/models/statlet.utils';
import { getCollectionPageRoute } from '../../../../../../app/collection-page/collection-page-routing-paths';

@Injectable()
@statletDataTypeService('dso', 'COLLECTION')
export class StatletDsoCollectionDataTypeService extends StatletDsoDataTypeService {
  constructor(public dsoNameService: DSONameService,
              public dataService: CollectionDataService) {
    super(dsoNameService);
  }

  getLinkByValue(value: string): StatletPointRoute {
    return { href: getCollectionPageRoute(value) };
  }
}
