import { Component, Input } from '@angular/core';
import { Item } from '../../../../../core/shared/item.model';
import { ThemedComponent } from '../../../../../shared/theme-support/themed.component';
import { ItemPageAbstractFieldComponent } from './item-page-abstract-field.component';

@Component({
    selector: 'ds-themed-item-page-abstract-field',
    styleUrls: [],
    templateUrl: '../../../../../shared/theme-support/themed.component.html',
})
/**
 * This component is used for displaying the abstract (dc.description.abstract) of an item
 */
export class ThemedItemPageAbstractFieldComponent extends ThemedComponent<ItemPageAbstractFieldComponent> {

  @Input() item: Item;

  protected inAndOutputNames: (keyof ItemPageAbstractFieldComponent & keyof this)[] = [
    'item',
  ];

  protected getComponentName(): string {
    return 'ItemPageAbstractFieldComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../../themes/${themeName}/app/item-page/simple/field-components/specific-field/abstract/item-page-abstract-field.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./item-page-abstract-field.component`);
  }
}
