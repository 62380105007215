/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ItemSearchResultListElementComponent as BaseComponent } from '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResult } from '../../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { Item } from '../../../../../../../../../app/core/shared/item.model';

@Component({
  selector: 'ds-item-search-result-list-element',
  styleUrls: ['../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html'
})
@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, undefined, 'atmire')
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, undefined, 'atmire')
@listableObjectComponent('Publication', ViewMode.ListElement, undefined, 'atmire')
@listableObjectComponent(Item, ViewMode.ListElement, undefined, 'atmire')
export class ItemSearchResultListElementComponent extends BaseComponent {

}
