<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event, true)"
  (legendLabelDeactivate)="onDeactivate($event, true)"
>
  <svg>
    <g [attr.transform]="transform" class="bar-chart chart">
      <g
        ngx-charts-series-vertical
        [xScale]="xScale"
        [yScale]="yScale"
        [colors]="colors"
        [series]="results"
        [dims]="dims"
        [gradient]="gradient"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="tooltipTemplate"
        [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="dataLabelFormatting"
        [activeEntries]="activeEntries"
        [roundEdges]="roundEdges"
        [animations]="animations"
        [noBarWhenZero]="noBarWhenZero"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        (select)="onClick($event)"
        (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event)"
      ></g>
    </g>
  </svg>
</ngx-charts-chart>
