import { NgModule } from '@angular/core';
import { DemoChartComponent } from './demo-chart.component';
import { WorldHeatMapComponent } from './world-heat-map.component';

const COMPONENTS = [
  DemoChartComponent,
  WorldHeatMapComponent,
];

@NgModule({
  imports: [],
  declarations: [
    ...COMPONENTS,
  ],
  providers: [],
  entryComponents: [],
  exports: [
    ...COMPONENTS,
  ],
})
/**
 * This module provides charts with the amcharts4 library
 */
export class AmchartsModule {
}
