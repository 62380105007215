<ds-pagination
  [paginationOptions]="config"
  [pageInfoState]="objects?.payload"
  [collectionSize]="objects?.payload?.totalElements"
  [sortOptions]="sortConfig"
  [hideGear]="hideGear"
  [hidePaginationDetail]="hidePaginationDetail"
  [hidePagerWhenSinglePage]="hidePagerWhenSinglePage"
  (pageChange)="onPageChange($event)"
  (pageSizeChange)="onPageSizeChange($event)"
  (sortDirectionChange)="onSortDirectionChange($event)"
  (sortFieldChange)="onSortFieldChange($event)"
  (paginationChange)="onPaginationChange($event)">
    <div class="row mt-2" *ngIf="objects?.hasSucceeded" @fadeIn>
      <div class="col"
           *ngFor="let object of objects?.payload?.page">
        <ds-listable-object-component-loader [object]="object" [viewMode]="viewMode" [context]="context"></ds-listable-object-component-loader>
      </div>
    </div>
  <ds-error *ngIf="objects.hasFailed" message="{{'error.objects' | translate}}"></ds-error>
  <ds-themed-loading *ngIf="objects.isLoading" message="{{'loading.objects' | translate}}"></ds-themed-loading>
</ds-pagination>
