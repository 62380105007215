/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dsSingleStatletWrapper]',
})
/**
 * Directive used as a hook to know where to inject the dynamic statlet wrapper
 */
export class SingleStatletWrapperDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
