/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a number into a percentage, displaying 3 digits after the decimal point by default
 */
@Pipe({ name: 'dsPercent' })
export class DsPercentPipe extends PercentPipe implements PipeTransform {
  transform(value: any, digitsInfo: string = '1.3-3', locale?: string): any {
    return super.transform(value, digitsInfo, locale);
  }
}
