/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { URLCombiner } from '../../app/core/url-combiner/url-combiner';
import { getAtmireCuaModulePath } from '../atmire-app-routing-paths';

export const STORAGE_REPORTS_MODULE_PATH = 'storage';
export const SEARCH_REPORTS_MODULE_PATH = 'search';
export const USAGE_REPORTS_MODULE_PATH = 'usage';
export const STATLETS_MODULE_PATH = 'reports';

/**
 * Get the route to the {@link StorageReport} module
 */
export function getStorageReportsModulePath() {
  return new URLCombiner(getAtmireCuaModulePath(), STORAGE_REPORTS_MODULE_PATH).toString();
}

/**
 * Get the route to the {@link StorageReport} module
 */
export function getSearchReportsModulePath() {
  return new URLCombiner(getAtmireCuaModulePath(), SEARCH_REPORTS_MODULE_PATH).toString();
}

/**
 * Get the route to the {@link UsageStatisticsHeader} module
 */
export function getUsageReportsModulePath() {
  return new URLCombiner(getAtmireCuaModulePath(), USAGE_REPORTS_MODULE_PATH).toString();
}

/**
 * Get the route to the statlets module
 */
export function getStatletsModulePath() {
  return new URLCombiner(getAtmireCuaModulePath(), STATLETS_MODULE_PATH).toString();
}
