import { AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy } from '@angular/core';

import { hasValue, isNotEmpty } from '../shared/empty.util';
import { BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';

/* tslint:disable:max-classes-per-file */

export class HeatMapEntry {
  readonly id: string;
  readonly value: number;

  constructor(id: string, value: number) {
    this.id = id;
    this.value = value;
  }
}

@Component({
  selector: 'ds-atmire-cua-world-heat-map',
  styleUrls: ['./world-heat-map.component.scss'],
  templateUrl: './world-heat-map.component.html',
})
export class WorldHeatMapComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() id: string;
  @Input() data: HeatMapEntry[] = [];
  @Input() labels: any;
  @Input() minColour = '#FFFFFF';
  @Input() maxColour = '#1a73ff';

  public map;

  private am4core;
  private am4maps;
  private am4geodataWorldLow;
  private loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    protected zone: NgZone,
  ) {
    Promise.all([
      import('@amcharts/amcharts4/core'),
      import('@amcharts/amcharts4/maps'),
      import('@amcharts/amcharts4-geodata/worldLow')
    ]).then((modules) => {
      this.am4core = modules[0];
      this.am4maps = modules[1];
      this.am4geodataWorldLow = modules[2].default;
      this.loaded$.next(true);
    }).catch((e) => {
      console.error('Error when creating chart', e);
    });
  }

  ngOnChanges(): void {
    this.setupData();
  }

  ngAfterViewInit(): void {
    this.loaded$.pipe(
      filter((loaded: boolean) => loaded === true),
      take(1)
    ).subscribe(() => {
      this.zone.runOutsideAngular(() => {
        const map = this.am4core.create(this.id, this.am4maps.MapChart);
        map.geodata = this.am4geodataWorldLow;
        if (hasValue(this.labels)) {
          map.geodataNames = this.labels;
        }

        const polygonSeries = new this.am4maps.MapPolygonSeries();
        polygonSeries.useGeodata = true;
        map.series.push(polygonSeries);

        // Configure series
        const polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.fill = this.am4core.color('#FFFFFF');
        polygonTemplate.stroke = this.am4core.color('#7a7a7a');

        // Create hover state and set alternative fill color
        const hs = polygonTemplate.states.create('hover');
        hs.properties.fill = this.am4core.color('#367B25');

        // Remove Antarctica
        polygonSeries.exclude = ['AQ'];

        // Add heat rule
        polygonSeries.heatRules.push({
          property: 'fill',
          target: polygonSeries.mapPolygons.template,
          min: this.am4core.color(this.minColour),
          max: this.am4core.color(this.maxColour),
        });

        this.map = map;

        this.setupData();
      });
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.map) {
        this.map.dispose();
      }
    });
  }

  setupData() {
    if (hasValue(this.map) && isNotEmpty(this.data)) {
      const series = [...this.data].map((e) => e.value).filter((v) => v > 0);
      const numberOfClasses = 10;
      const allValues = [...this.data].map((entry) => entry.value);
      let classes = [];
      let getClass;
      const maxValue = Math.max(...allValues);
      if (maxValue <= numberOfClasses) {
        // geometric progression is not adequate when there are only small numbers
        classes = Array.from(new Array(maxValue + 1), (x, i) => i);
        getClass = (value: number) => value;
        this.data.push(new HeatMapEntry('', 0)); // makes it start a 0
      } else {
        // geometric progression
        // regrouping the results for a better distribution of colors
        // otherwise an outlier will bleach out the rest of the map
        const skipClasses = 2;
        classes = this.getGeometricProgressionClasses(series, numberOfClasses + skipClasses)
          .slice(skipClasses);
        // e.g. classes = [2, 3, 5, 7, 11, 17, 25, 38, 58, 87, 131]
        // these are the thresholds for colors
        // console.log(classes);
        getClass = (value: number) => {
          // find which threshold the value matches and assign the index
          let result = 0;
          for (let i = 0, classesLength = classes.length; i < classesLength; i++) {
            if (classes[i] < value) {
              result = i;
            }
          }
          // console.log(value, result);
          return result;
        };
      }
      const polygonSeries = this.map.series.getIndex(0);
      polygonSeries.data = [...this.data]
        .map((e) => new HeatMapEntry(e.id, getClass(e.value)));

      // https://www.amcharts.com/docs/v4/concepts/legend/heat-legend/
      const heatLegend = this.map.createChild(this.am4maps.HeatLegend);
      heatLegend.series = this.map.series.getIndex(0);
      heatLegend.width = this.am4core.percent(100);
      // heatLegend.markerCount = numberOfClasses + 1;
      heatLegend.valueAxis.renderer.labels.template.adapter.add('text',
        (labelText) => {
          const value = classes[+labelText];
          return '' + (value ? Math.floor(value) : '');
        });

      // https://www.amcharts.com/docs/v4/concepts/formatters/formatting-strings/
      // polygonTemplate.tooltipText = '{name} {value}';
      // https://stackoverflow.com/questions/56349455/dynamic-tooltip-text-amchart
      const polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.adapter.add('tooltipText', (text, target) => {
        const countryCode = target.tooltipDataItem.dataContext.id;
        return '{name}: ' + this.getValue(countryCode);
      });
    }
  }

  getGeometricProgressionClasses(series: number[], numberOfClasses: number): number[] {
    // taken from the geostats library
    // https://github.com/simogeo/geostats
    // (it didn't play well in the production build)

    let a = [];
    const tmpMin = Math.min(...series);
    const tmpMax = Math.max(...series);

    const logMax = Math.log(tmpMax) / Math.LN10; // max decimal logarithm (or base 10)
    const logMin = Math.log(tmpMin) / Math.LN10; // min decimal logarithm (or base 10)

    const interval = (logMax - logMin) / numberOfClasses;

    // we compute log bounds
    for (let i = 0; i < numberOfClasses; i++) {
      if (i === 0) {
        a[i] = logMin;
      } else {
        a[i] = a[i - 1] + interval;
      }
    }

    // we compute antilog
    a = a.map((x) => Math.pow(10, x));

    // and we finally add max value
    a.push(tmpMax);

    return a;
  }

  getValue(countryCode: string): number {
    const filtered = [...this.data].filter((entry) => entry.id === countryCode);
    return filtered.length > 0 ? filtered[0].value : 0;
  }
}
