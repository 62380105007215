<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="pie-chart">
  <ngx-charts-pie-chart *ngIf="data$ | async"
                        [view]="view"
                        [results]="data$ | async"
                        [labels]="size !== 'small'"
                        [scheme]="colors$ | async"
  ></ngx-charts-pie-chart>
</div>
