/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { StatletPointRoute } from '../../data/models/statlet.utils';
import { getStatletPagePath } from '../../../statlets-routing-paths';
import { AtmireValuePairDataService } from '../../../../shared-reports/value-pairs/atmire-value-pair-data.service';
import { StatletCountryCodeDataTypeService } from './statlet-country-code-data-type.service';

@Injectable()
@statletDataTypeService('visitor', 'countryCode', 'SiteTopCountriesCurrentUser')
export class StatletCountryCodeUserDataTypeService extends StatletCountryCodeDataTypeService {
  constructor(protected valuePairService: AtmireValuePairDataService) {
    super(valuePairService);
  }

  getLinkByValue(value: string): StatletPointRoute {
    return {href: getStatletPagePath('SiteTopItemsCurrentUser'), queryParams: {['f.visitor.countryCode']: value}};
  }
}
