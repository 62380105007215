/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SearchService } from '../../../../app/core/shared/search/search.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '../../../../app/core/services/route.service';
import { RequestService } from '../../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../../app/core/cache/builders/remote-data-build.service';
import { LinkService } from '../../../../app/core/cache/builders/link.service';
import { HALEndpointService } from '../../../../app/core/shared/hal-endpoint.service';
import { CommunityDataService } from '../../../../app/core/data/community-data.service';
import { DSpaceObjectDataService } from '../../../../app/core/data/dspace-object-data.service';
import { PaginationService } from '../../../../app/core/pagination/pagination.service';
import { AtmireSavedItemListStoreService } from '../../../atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { SearchConfigurationService } from '../../../../app/core/shared/search/search-configuration.service';
import { PaginatedSearchOptions } from '../../../../app/shared/search/models/paginated-search-options.model';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createSavedItemListsProjectionArgs } from '../../../atmire-saved-item-list/atmire-saved-item-list.util';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AtmireSearchService extends SearchService {
  constructor(protected router: Router,
              protected routeService: RouteService,
              protected requestService: RequestService,
              protected rdb: RemoteDataBuildService,
              protected linkService: LinkService,
              protected halService: HALEndpointService,
              protected communityService: CommunityDataService,
              protected dspaceObjectService: DSpaceObjectDataService,
              protected paginationService: PaginationService,
              protected savedItemListStoreService: AtmireSavedItemListStoreService,
              protected searchConfigurationService: SearchConfigurationService,
              protected angulartics2: Angulartics2,
  ) {
    super(router, routeService, requestService, rdb, linkService, halService, communityService, dspaceObjectService, paginationService, searchConfigurationService, angulartics2);
  }

  getEndpoint(searchOptions?: PaginatedSearchOptions): Observable<string> {
    return observableCombineLatest(this.halService.getEndpoint(this.searchLinkPath), this.savedItemListStoreService.getStoredListIdsObs()).pipe(
      map(([url, lists]: [string, string[]]) => {
        const options = Object.assign(new PaginatedSearchOptions({}), searchOptions);
        const args = createSavedItemListsProjectionArgs(...lists);
        if (hasValue(options) || isNotEmpty(args)) {
          return options.toRestUrl(url, args);
        } else {
          return url;
        }
      })
    );
  }
}
