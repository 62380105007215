/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Observable } from 'rxjs';
import { StatletPoint } from '../../data/models/statlet-point.model';
import { StatletPointRoute } from '../../data/models/statlet.utils';

export abstract class StatletDataTypeService {
  abstract transform(value: string): Observable<string>;
  abstract getLinkByPoint(point: StatletPoint): StatletPointRoute;
  abstract getLinkByValue(value: string): StatletPointRoute;
}
