/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { BrowseService } from '../../../app/core/browse/browse.service';
import { Injectable } from '@angular/core';
import { RequestService } from '../../../app/core/data/request.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { BrowseDefinitionDataService } from '../../../app/core/browse/browse-definition-data.service';
import { HrefOnlyDataService } from '../../../app/core/data/href-only-data.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { AtmireSavedItemListStoreService } from '../../atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { BrowseEntrySearchOptions } from '../../../app/core/browse/browse-entry-search-options.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { BrowseEntry } from '../../../app/core/shared/browse-entry.model';
import { getBrowseDefinitionLinks } from '../../../app/core/shared/operators';
import { hasValueOperator, isNotEmpty } from '../../../app/shared/empty.util';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { URLCombiner } from '../../../app/core/url-combiner/url-combiner';
import { createSavedItemListsProjectionArgs } from '../../atmire-saved-item-list/atmire-saved-item-list.util';

@Injectable()
export class AtmireBrowseService extends BrowseService {
  constructor(
    protected requestService: RequestService,
    protected halService: HALEndpointService,
    protected browseDefinitionDataService: BrowseDefinitionDataService,
    protected hrefOnlyDataService: HrefOnlyDataService,
    protected rdb: RemoteDataBuildService,
    protected savedItemListStoreService: AtmireSavedItemListStoreService,
  ) {
    super(requestService, halService, browseDefinitionDataService, hrefOnlyDataService, rdb);
  }

  getBrowseEntriesFor(options: BrowseEntrySearchOptions): Observable<RemoteData<PaginatedList<BrowseEntry>>> {
    const href$ = this.getBrowseDefinitions().pipe(
      getBrowseDefinitionLinks(options.metadataDefinition),
      hasValueOperator(),
      map((_links: any) => {
        const entriesLink = _links.entries.href || _links.entries;
        return entriesLink;
      }),
      hasValueOperator(),
      switchMap((href: string) => {
        return this.savedItemListStoreService.getStoredListIDObs().pipe(
          distinctUntilChanged(),
          map((listID) => {
            const args = [...createSavedItemListsProjectionArgs(listID)];
            if (isNotEmpty(options.scope)) {
              args.push(`scope=${options.scope}`);
            }
            if (isNotEmpty(options.sort)) {
              args.push(`sort=${options.sort.field},${options.sort.direction}`);
            }
            if (isNotEmpty(options.pagination)) {
              args.push(`page=${options.pagination.currentPage - 1}`);
              args.push(`size=${options.pagination.pageSize}`);
            }
            if (isNotEmpty(options.startsWith)) {
              args.push(`startsWith=${options.startsWith}`);
            }
            if (isNotEmpty(args)) {
              href = new URLCombiner(href, `?${args.join('&')}`).toString();
            }
            return href;
          })
        );
      })
    );
    return this.hrefOnlyDataService.findAllByHref<BrowseEntry>(href$);
  }
}
