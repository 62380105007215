<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="page-with-sidebar page-with-sidebar-{{minExpandedSize}}" [ngClass]="stretch ? 'container-fluid' : 'container'">
  <div [ngClass]="stretch ? 'd-flex' : 'row'">
    <div class="row-with-sidebar row-offcanvas row-offcanvas-left"
         [@pushInOut]="(isSidebarCollapsed$ | async) ? 'collapsed' : 'expanded'">
      <div id="{{id}}-sidebar-content"
           class="sidebar-content {{sidebarClasses | async}}" [ngClass]="stretch ? 'flex-grow-1 stretch-sidebar-content' : (('col-12 col-' + minExpandedSize + '-') + sideBarWidth)">
        <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
      </div>
      <div [ngClass]="(stretch ? 'flex-grow-1 stretch-content' : (('col-12 col-' + minExpandedSize + '-') + (12 - sideBarWidth))) + ((isSidebarCollapsed$ | async) ? '' : ' px-3')">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
