import { Component, Input, OnInit } from '@angular/core';
import { DSONameService } from '../../../../core/breadcrumbs/dso-name.service';
import { Bitstream } from '../../../../core/shared/bitstream.model';

/**
 * Component displaying a bitstream name on the item-edit-bitstream page.
 */
@Component({
  selector: 'ds-item-edit-bitstream-name',
  styleUrls: ['../item-bitstreams.component.scss'],
  templateUrl: './item-edit-bitstream-name.component.html',
})
export class ItemEditBitstreamNameComponent implements OnInit {

  /**
   * The bitstream of this field
   */
  @Input() bitstream: Bitstream;

  /**
   * The bitstream's name
   */
  bitstreamName: string;

  constructor(
    protected dsoNameService: DSONameService,
  ) {
  }

  ngOnInit(): void {
    this.bitstreamName = this.dsoNameService.getName(this.bitstream);
  }
}
