/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { hasNoValue, isNotEmpty } from '../../../../../app/shared/empty.util';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ds-export-as-image-modal',
  templateUrl: './export-as-image-modal.component.html',
  styleUrls: ['./export-as-image-modal.component.scss']
})
export class ExportAsImageModalComponent implements OnInit {
  @Input() fileName;
  @Input() fileTypes: string[];
  @Input() fileType: string;
  @Output() triggerSave: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();
  loading = new BehaviorSubject(false);

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (hasNoValue(this.fileType) && isNotEmpty(this.fileTypes)) {
      this.fileType = this.fileTypes[0];
    }
  }

  save(filename, format) {
    this.loading.next(true);
    // This somehow helps prevent blocking the change detection
    // ngZone.runOutsideAngular does not solve this issue
    setTimeout(() => this.triggerSave.emit([filename, format]), 0);
  }
}
