import { Component } from '@angular/core';
import { ThemedComponent } from '../theme-support/themed.component';
import { LangSwitchComponent } from './lang-switch.component';

@Component({
  selector: 'ds-themed-lang-switch',
  styleUrls: [],
  templateUrl: '../../shared/theme-support/themed.component.html',
})

/**
 * Component representing a switch for changing the interface language throughout the application
 * If only one language is active, the component will disappear as there are no languages to switch to.
 */
export class ThemedLangSwitchComponent extends ThemedComponent<LangSwitchComponent> {

  protected getComponentName(): string {
    return 'LangSwitchComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/shared/lang-switch/lang-switch.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./lang-switch.component`);
  }
}
