/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { type } from '../../../app/shared/ngrx/type';
import { Action } from '@ngrx/store';
import { AtmireSavedItemList } from '../models/atmire-saved-item-list.model';

/**
 * The types of actions able to be performed on the {@link AtmireSavedItemListState}
 */
export const AtmireSavedItemListActionTypes = {
  SET: type('dspace/atmire-saved-item-list/SET'),
  CLEAR: type('dspace/atmire-saved-item-list/CLEAR'),
  DELETE: type('dspace/atmire-saved-item-list/DELETE'),
};

/**
 * Abstract action to perform on the {@link AtmireSavedItemListState}
 */
/* tslint:disable:max-classes-per-file */
export abstract class AtmireSavedItemListAction implements Action {
  // tslint:disable-next-line:no-shadowed-variable
  constructor(public type) {
  }
}

/**
 * Action to store the ID of the user's current {@link AtmireSavedItemList} in the {@link AtmireSavedItemListState}
 */
export class AtmireSavedItemListSetAction extends AtmireSavedItemListAction {
  id: string;
  name: string;
  userId?: string;

  constructor(id: string, name: string, userId?: string) {
    super(AtmireSavedItemListActionTypes.SET);
    this.id = id;
    this.name = name;
    this.userId = userId;
  }
}

/**
 * Action to store the ID of the user's current {@link AtmireSavedItemList} in the {@link AtmireSavedItemListState}
 */
export class AtmireSavedItemListClearAction extends AtmireSavedItemListAction {

  constructor() {
    super(AtmireSavedItemListActionTypes.CLEAR);
  }
}

/**
 * Action to delete a list from {@link AtmireSavedItemListState} by ID
 */
export class AtmireSavedItemListDeleteAction extends AtmireSavedItemListAction {
  id: string;

  constructor(id: string) {
    super(AtmireSavedItemListActionTypes.DELETE);
    this.id = id;
  }
}
