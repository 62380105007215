/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { autoserialize, autoserializeAs, deserialize, inheritSerialization } from 'cerialize';
import { SEARCH_TERM } from './search-term.resource-type';
import { SearchTermValues } from './search-term-values.model';
import { HALLink } from '../../../../app/core/shared/hal-link.model';

/**
 * Class representing a DSpace SearchTerm
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class SearchTerm extends DSpaceObject {
  static type = SEARCH_TERM;

  /**
   * The identifier of this SearchTerm
   */
  @autoserialize
  id: string;

  /**
   * The scope of this search term
   * This is the ID of the object this SearchTerm resembles
   */
  @autoserialize
  scope: string;

  /**
   * The search term in string form
   */
  @autoserializeAs('search_term')
  searchTerm: string;

  /**
   * The earliest date this term is storing statistics for
   */
  @autoserializeAs('start_date')
  startDate: Date;

  /**
   * The latest date this term is storing statistics for
   */
  @autoserializeAs('end_date')
  endDate: Date;

  /**
   * The statistical values of this search term
   */
  @autoserializeAs(SearchTermValues)
  values: SearchTermValues;

  @deserialize
  _links: {
    self: HALLink;
  };
}
