<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-select [items]="options$ | async"
           [placeholder]="placeholder"
           [multiple]="true"
           [(ngModel)]="selectedValues"
           [typeahead]="query$"
           [bindLabel]="'display'"
           [bindValue]="'value'"
           (ngModelChange)="change()"
           (blur)="submit(selectedValues)"
           [loading]="loading">
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    <div class="d-flex">
      <div class="flex-grow-1">
        <span>{{ item.display }}</span>
      </div>
      <div class="text-primary align-self-center" *ngIf="item$.selected">
        <i class="fas fa-check fa-fw"></i>
      </div>
    </div>
  </ng-template>
</ng-select>
