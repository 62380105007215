<td>
    <div class="metadata-field">
        <div *ngIf="!(editable | async)">
            <span >{{metadata?.key?.split('.').join('.&#8203;')}}</span>
        </div>
        <div *ngIf="(editable | async)" class="field-container">
            <ds-validation-suggestions [disable]="fieldUpdate.changeType != 1" [suggestions]="(metadataFieldSuggestions | async)"
                                         [(ngModel)]="metadata.key"
                                         [url]="this.url"
                                         [metadata]="this.metadata"
                                  (submitSuggestion)="update(suggestionControl)"
                                  (clickSuggestion)="update(suggestionControl)"
                                  (typeSuggestion)="update(suggestionControl)"
                                  (dsClickOutside)="checkValidity(suggestionControl)"
                                  (findSuggestions)="findMetadataFieldSuggestions($event)"
                                  #suggestionControl="ngModel"
                                  [valid]="(valid | async) !== false"
                                  dsAutoFocus autoFocusSelector=".suggestion_input"
                                  [ngModelOptions]="{standalone: true}"
            ></ds-validation-suggestions>
        </div>
        <small class="text-danger"
               *ngIf="(valid | async) === false">{{"item.edit.metadata.metadatafield.invalid" | translate}}</small>
    </div>
</td>
<td class="w-100">
    <div class="value-field">
        <div *ngIf="!(editable | async)">
            <span class="dont-break-out">{{metadata?.value}}</span>
        </div>
        <div *ngIf="(editable | async)" class="field-container">
            <textarea class="form-control" type="textarea" attr.aria-labelledby="fieldValue" [(ngModel)]="metadata.value" [dsDebounce]
                      (onDebounce)="update()"></textarea>
        </div>
    </div>
</td>
<td class="text-center">
    <div class="language-field">
        <div *ngIf="!(editable | async)">
            <span>{{metadata?.language}}</span>
        </div>
        <div *ngIf="(editable | async)" class="field-container">
            <input class="form-control" type="text" attr.aria-labelledby="fieldLang" [(ngModel)]="metadata.language" [dsDebounce]
                   (onDebounce)="update()"/>
        </div>
    </div>
</td>
<td class="text-center">
    <div class="btn-group edit-field">
        <button [disabled]="!(canSetEditable() | async)" *ngIf="!(editable | async)"
                (click)="setEditable(true)" class="btn btn-outline-primary btn-sm"
                title="{{'item.edit.metadata.edit.buttons.edit' | translate}}">
            <i class="fas fa-edit fa-fw"></i>
        </button>
        <button [disabled]="!(canSetUneditable() | async) || (valid | async) === false" *ngIf="(editable | async)"
                (click)="setEditable(false)" class="btn btn-outline-success btn-sm"
                title="{{'item.edit.metadata.edit.buttons.unedit' | translate}}">
            <i class="fas fa-check fa-fw"></i>
        </button>
        <button [disabled]="!(canRemove() | async)" (click)="remove()"
                class="btn btn-outline-danger btn-sm"
                title="{{'item.edit.metadata.edit.buttons.remove' | translate}}">
            <i class="fas fa-trash-alt fa-fw"></i>
        </button>
        <button [disabled]="!(canUndo() | async)" (click)="removeChangesFromField()"
                class="btn btn-outline-warning btn-sm"
                title="{{'item.edit.metadata.edit.buttons.undo' | translate}}">
            <i class="fas fa-undo-alt fa-fw"></i>
        </button>
    </div>
</td>
