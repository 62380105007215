/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PoolSearchResultListElementComponent as BaseComponent } from '../../../../../../../app/shared/object-list/my-dspace-result-list-element/pool-search-result/pool-search-result-list-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { PoolTaskSearchResult } from '../../../../../../../app/shared/object-collection/shared/pool-task-search-result.model';

@Component({
  selector: 'ds-pool-search-result-list-element',
  styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss'],
  templateUrl: './pool-search-result-list-element.component.html'
})
@listableObjectComponent(PoolTaskSearchResult, ViewMode.ListElement, undefined, 'atmire')
export class PoolSearchResultListElementComponent extends BaseComponent {

}
