/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AlertComponent } from '../../../app/shared/alert/alert.component';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { trigger } from '@angular/animations';
import { fadeOutLeave, fadeOutState } from '../../../app/shared/animations/fade';

@Component({
  selector: 'ds-atmire-alert',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('enterLeave', [
      fadeOutLeave, fadeOutState,
    ])
  ],
  templateUrl: './atmire-alert.component.html',
  styleUrls: ['../../../app/shared/alert/alert.component.scss']
})
export class AtmireAlertComponent extends AlertComponent {
  /**
   * Extra classes to assign to the alert
   */
  @Input() classes: string;
}

