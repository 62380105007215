/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Action } from '@ngrx/store';
import { type } from '../../../../../../app/shared/ngrx/type';

/**
 * Type values used by the statlet actions
 */
export const StatletActionTypes = {
  SHOW_STATLETS_ACTION: type('atmire/statlets/show'),
  HIDE_STATLETS_ACTION: type('atmire/statlets/hide'),
};

/* tslint:disable:max-classes-per-file */

/**
 * The user wants to see the statlets
 */
export class ShowStatletsAction implements Action  {
  public type: string = StatletActionTypes.SHOW_STATLETS_ACTION;
}

/**
 * The user does not want to see the statlets
 */
export class HideStatletsAction implements Action  {
  public type: string = StatletActionTypes.HIDE_STATLETS_ACTION;
}

/**
 * Union type encompassing all actions related to statlets
 */
export type StatletActions
  = ShowStatletsAction
  | HideStatletsAction;
