/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  ComponentFactoryResolver,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Statlet } from '../../data/models/statlet.model';
import { DynamicSingleStatletComponent } from '../graph-types/dynamic-single-statlet.component';
import { SingleStatletDirective } from '../graph-types/single-statlet.directive';
import { hasNoValue, hasValue } from '../../../../../../app/shared/empty.util';
import { DEFAULT_STATLET_CONTEXT, SINGLE_STATLET_COMPONENT_FACTORY } from '../graph-types/single-statlet.decorator';
import { GenericConstructor } from '../../../../../../app/core/shared/generic-constructor';
import { Context } from '../../../../../../app/core/shared/context.model';
import { ThemeService } from '../../../../../../app/shared/theme-support/theme.service';
import { StatletGraphType } from '../graph-types/statlet-graph-types.model';

@Component({
  selector: 'ds-atmire-cua-single-statlet-graph-loader',
  templateUrl: './single-statlet-graph-loader.component.html',
  styleUrls: ['./single-statlet-graph-loader.component.scss']
})
/**
 * Component loading the appropriate graph for a Statlet
 */
export class SingleStatletGraphLoaderComponent implements OnChanges {
  /**
   * The object to display statlets for
   */
  @Input() statlet: Statlet;

  /**
   * The graph type this Statlet should be rendered in
   * If none provided, the type of the Statlet will be used
   * This allows us to optionally render any Statlet in any Graph type
   */
  @Input() type: StatletGraphType;

  /**
   * The view dimensions for the graph to use: should be [width, height] in pixels
   * This ensures the graph size stays responsive to the container's size, as long as the view is passed on to the graph
   * component within its template
   */
  @Input() view: number[];

  /**
   * The size the statlet is rendered in
   */
  @Input() size: string;

  /**
   * The context to render the Statlets for
   */
  @Input() context: Context = DEFAULT_STATLET_CONTEXT;

  /**
   * An optional palette to start from. If left out, the default palette is used.
   * A palette should have at least 2 colors and is assumed to be ordered in a
   * gradient
   */
  @Input() palette: string[];

  /**
   * The dynamic statlet's component instance
   */
  componentInstance: DynamicSingleStatletComponent;

  /**
   * Directive to determine where the dynamic child component is located
   */
  @ViewChild(SingleStatletDirective, { static: true }) statletDirective: SingleStatletDirective;

  constructor(protected componentFactoryResolver: ComponentFactoryResolver,
              protected themeService: ThemeService,
              @Inject(SINGLE_STATLET_COMPONENT_FACTORY) private getComponentForSingleStatletType: (type: string, context?: Context, theme?: string) => GenericConstructor<any>) { }

  ngOnChanges(changes: SimpleChanges) {
    if (hasNoValue(this.componentInstance)) {
      if (hasValue(this.statletDirective)) {
        const component = this.getComponentForSingleStatletType(hasValue(this.type) ? this.type.id : this.statlet.graphtype.id, this.context, this.themeService.getThemeName());
        if (hasValue(component)) {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
          if (hasValue(componentFactory)) {
            const viewContainerRef = this.statletDirective.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(componentFactory);
            const statletInstance = componentRef.instance as any;
            if (hasValue(statletInstance)) {
              this.componentInstance = statletInstance;
              this.componentInstance.statlet = this.statlet;
              this.componentInstance.view = this.view;
              this.componentInstance.palette = this.palette;
              this.componentInstance.size = this.size;
            }
          }
        }
      }
    } else {
      this.componentInstance.view = this.view;
    }
  }

}
