/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../../app/core/data/data.service';
import { SearchReport } from '../search-reports-models/search-report.model';
import { Injectable } from '@angular/core';
import { dataService } from '../../../../app/core/cache/builders/build-decorators';
import { SEARCH_REPORT } from '../search-reports-models/search-report.resource-type';
import { RequestService } from '../../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DSOChangeAnalyzer } from '../../../../app/core/data/dso-change-analyzer.service';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { FindListOptions } from '../../../../app/core/data/request.models';
import { RequestParam } from '../../../../app/core/cache/models/request-param.model';
import { switchMap } from 'rxjs/operators';
import { FollowLinkConfig } from '../../../../app/shared/utils/follow-link-config.model';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';

/**
 * The service handling all REST requests for SearchReport
 */
@Injectable()
@dataService(SEARCH_REPORT)
export class SearchReportsDataService extends DataService<SearchReport> {
  protected linkPath = 'searchreports';
  protected searchPathObject = 'object';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<SearchReport>
  ) {
    super();
  }

  /**
   * Find a {@link SearchReport} by its {@link DSpaceObject}
   * @param object        {@link DSpaceObject} to return a {@link SearchReport} for
   * @param query         Query to search for
   * @param start         Start date in string format
   * @param end           End date in string format
   * @param facet         Whether or not to include facet searches
   * @param linksToFollow List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findByObject(object: DSpaceObject, query?: string, start?: string, end?: string, facet?: boolean, ...linksToFollow: FollowLinkConfig<SearchReport>[]): Observable<RemoteData<SearchReport>> {
    const options = Object.assign(new FindListOptions(), {
      searchParams: [new RequestParam('uri', object._links.self.href)]
    });
    this.addSearchParam(options, 'query', query);
    if (isNotEmpty(start)) {
      this.addSearchParam(options, 'start', start);
    }
    if (isNotEmpty(end)) {
      this.addSearchParam(options, 'end', end);
    }
    this.addSearchParam(options, 'facet', facet);
    return this.getSearchByHref(this.searchPathObject, options, ...linksToFollow).pipe(
      switchMap((href) => this.findByHref(href, true, true, ...linksToFollow))
    );
  }

  /**
   * Add a search parameter to {@link FindListOptions}
   * Skip if the value is undefined
   * @param options
   * @param paramName
   * @param paramValue
   */
  addSearchParam(options: FindListOptions, paramName: string, paramValue: any) {
    if (hasValue(paramValue)) {
      options.searchParams.push(new RequestParam(paramName, paramValue));
    }
  }
}
