/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dsExportAsImageDom]'
})
export class ExportAsImageDomDirective implements AfterViewInit {
  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {
    // Make sure the component that will be exported as an image is an actual block element so it has a height/width
    // Maybe there's a better solution for this, as it might be difficult to debug?
    this.element.nativeElement.classList.add('d-block');
  }
}
