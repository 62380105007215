/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { HALResource } from '../../../../app/core/shared/hal-resource.model';
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { autoserialize, inheritSerialization } from 'cerialize';
import { ATMIRE_VALUE_PAIR } from './atmire-value-pair.resource-type';

@typedObject
@inheritSerialization(HALResource)
export class AtmireValuePair extends HALResource {
  static type = ATMIRE_VALUE_PAIR;

  type = ATMIRE_VALUE_PAIR;

  @autoserialize
  id: string;

  @autoserialize
  display: string;

  @autoserialize
  value: string;

  @autoserialize
  language: string;

  @autoserialize
  pairtype: string;
}
