<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="card my-3">
    <div class="card-header p-2 d-flex">
        <div class="flex-grow-1">
            <ng-container *ngTemplateOutlet="headerButtons"></ng-container>
        </div>
        <div class="ml-1">
            <button class="btn btn-sm btn-light px-1 ml-1" (click)="isCollapsed = !isCollapsed">
                <i *ngIf="isCollapsed" class="fas fa-fw fa-angle-down"></i>
                <i *ngIf="!isCollapsed" class="fas fa-fw fa-angle-up"></i>
            </button>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" (expanded)="toggle.emit(false)" (collapsed)="toggle.emit(true)">
        <div class="card-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>
