/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize } from 'cerialize';
import { isNotEmpty } from '../../../../../../app/shared/empty.util';
import { EquatableObject } from '../../../../../../app/core/utilities/equatable';
import { excludeFromEquals } from '../../../../../../app/core/utilities/equals.decorators';

export class StatletFilter extends EquatableObject<StatletFilter> {
  static VALUE_SEPARATOR = '||';

  @autoserialize
  category: string;

  @autoserialize
  type: string;

  @autoserialize
  values: string[];

  @autoserialize
  @excludeFromEquals
  information: string;

  @autoserialize
  filterField: string;

  get firstValue(): string {
    return isNotEmpty(this.values) ? this.values[0] : null;
  }

  get hasValue(): boolean {
    return isNotEmpty(this.firstValue);
  }

  get valueString(): string {
    if (this.hasValue) {
      return this.values.join(StatletFilter.VALUE_SEPARATOR);
    } else {
      return null;
    }
  }

  equals(other: StatletFilter): boolean {
    if (other.valueString !== this.valueString) {
      return false;
    }
    return super.equals(other);
  }

  equalsWithoutValue(other: StatletFilter): boolean {
    return super.equals(other);
  }
}
