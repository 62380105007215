/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AtmireSavedItemListDataService,
  STORAGE_ATMIRE_LIST_ID_PREFIX
} from '../data-services/atmire-saved-item-list-data.service';
import { Action, Store } from '@ngrx/store';
import { StoreActionTypes } from '../../../app/store.actions';
import { map, switchMap, take, filter, tap } from 'rxjs/operators';
import { AuthActionTypes } from '../../../app/core/auth/auth.actions';
import { hasValue, hasValueOperator } from '../../../app/shared/empty.util';
import { RemoteData } from '../../../app/core/data/remote-data';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { AtmireAuthService } from '../../core/auth/atmire-auth.service';
import { AtmireSavedItemList } from '../models/atmire-saved-item-list.model';
import { EPerson } from '../../../app/core/eperson/models/eperson.model';
import { AtmireSavedItemListStoreService } from './atmire-saved-item-list-store.service';
import { LocalStorageActionTypes, LocalStorageStoreAction } from '../../core/services/local-storage.actions';

/**
 * NGRX effects for {@link AtmireSavedItemListAction}s
 */
@Injectable()
export class AtmireSavedItemListEffects {

  /**
   * Effect listening to the app's startup and authentication of a user
   * Retrieves the {@link AtmireSavedItemList} for the current user and saves its ID in the {@link AtmireSavedItemListState}
   */
  @Effect({dispatch: false})
  public changeAtmireSavedItemlist$: Observable<Action> = this.actions$
    .pipe(
      ofType(StoreActionTypes.REHYDRATE, StoreActionTypes.REPLAY, AuthActionTypes.AUTHENTICATED_SUCCESS),
      switchMap((action) => {
        this.savedItemListStoreService.clear();
        return observableCombineLatest(
          this.savedItemListService.findAllListsIgnoreStore().pipe(
            hasValueOperator(),
            filter((rdList: [RemoteData<AtmireSavedItemList>]) => {
              let allCompleted = true;
              rdList.forEach((rd) => {
                if (!rd.hasCompleted || rd.isStale) {
                  allCompleted = false;
                }
              });
              return allCompleted;
            }),
          ),
          this.authService.getAuthenticatedUserOrNullFromStore().pipe(
            take(1)
          ),
        ).pipe(
          map(([rdList, user]: [[RemoteData<AtmireSavedItemList>], EPerson]) => {
            this.savedItemListStoreService.clear();
            rdList.forEach((rd) => {
              if (rd.hasSucceeded && hasValue(rd.payload)) {
                this.savedItemListStoreService.addListToStore(rd.payload, user);
              }
            });
            return action;
          })
        );
      })
    );

  @Effect({dispatch: false})
  public clearAtmireSavedItemListsRequests$: Observable<Action> = this.actions$
    .pipe(
      ofType(LocalStorageActionTypes.STORAGE),
      filter((action: LocalStorageStoreAction) => action.event.key.startsWith(STORAGE_ATMIRE_LIST_ID_PREFIX)),
      tap((action: LocalStorageStoreAction) => {
        this.savedItemListService.removeBaseRequests();
      }),
    );

  constructor(private actions$: Actions,
              private savedItemListService: AtmireSavedItemListDataService,
              private savedItemListStoreService: AtmireSavedItemListStoreService,
              private authService: AtmireAuthService) {
  }
}
