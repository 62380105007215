/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize } from 'cerialize';
import { AlternativeLabel } from '../../../../shared/models/alternative-label.model';

export class StatletHeader {
  @autoserialize
  headerCategory: string;

  @autoserialize
  headerType: string;

  @autoserialize
  headerId: string;

  @autoserialize
  headerName: string;

  @autoserialize
  alternativeLabels: {
    [size: string]: AlternativeLabel
  };
}
