<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="vertical-bar-chart">
  <ngx-charts-bar-vertical-2d *ngIf="data$ | async"
     [results]="data$ | async"
     [view]="view"
     [scheme]="colors$ | async"
     [xAxis]="true"
     [yAxis]="true"
     [xAxisLabel]="'statlets.type.' + statlet.shortName + '.x-axis' | translate"
     [yAxisLabel]="'statlets.type.' + statlet.shortName + '.y-axis' | translate"
     [showXAxisLabel]="true"
     [showYAxisLabel]="true"
     [barPadding]="1"
     [groupPadding]="5"
  ></ngx-charts-bar-vertical-2d>
</div>
