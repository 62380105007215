/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../app/shared/shared.module';
import { ReportSummaryComponent } from './report-summary/report-summary.component';
import { ValuePairsSelectorComponent } from './value-pairs/value-pairs-selector/value-pairs-selector.component';
import { AtmireValuePairDataService } from './value-pairs/atmire-value-pair-data.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { AtmireSharedModule } from '../../shared/atmire-shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AtmireSharedModule,
    NgSelectModule,
  ],
  declarations: [
    ReportSummaryComponent,
    ValuePairsSelectorComponent,
  ],
  exports: [
    ReportSummaryComponent,
    ValuePairsSelectorComponent,
  ],
  providers: [
    AtmireValuePairDataService,
  ],
})
export class SharedReportsModule {
}
