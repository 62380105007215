/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuResolver } from '../app/menu.resolver';
import { AuthBlockingGuard } from '../app/core/auth/auth-blocking.guard';
import { ServerCheckGuard } from '../app/core/server-check/server-check.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthBlockingGuard],
    canActivateChild: [ServerCheckGuard],
    resolve: [MenuResolver],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      // add custom routes here
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientAppRoutingModule { }
