/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { StatletDefaultDataTypeService } from './statlet-default-data-type.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { StatletPoint } from '../../data/models/statlet-point.model';
import { StatletPointRoute } from '../../data/models/statlet.utils';
import { getStatletPagePath } from '../../../statlets-routing-paths';
import {
  AtmireValuePairDataService,
  BY_COUNTRY_ENDPOINT,
} from '../../../../shared-reports/value-pairs/atmire-value-pair-data.service';
import { getAllCompletedRemoteData } from '../../../../../../app/core/shared/operators';
import { isNotEmpty } from '../../../../../../app/shared/empty.util';

@Injectable()
@statletDataTypeService('visitor', 'countryCode')
export class StatletCountryCodeDataTypeService extends StatletDefaultDataTypeService {
  constructor(protected valuePairService: AtmireValuePairDataService) {
    super();
  }

  transform(value: string): Observable<string> {
    return this.valuePairService.findAllByHref(this.valuePairService.getSearchEndpointByPathWithValue(BY_COUNTRY_ENDPOINT, value)).pipe(
      getAllCompletedRemoteData(),
      map((rd) => {
        if (rd.hasSucceeded && isNotEmpty(rd.payload.page)) {
          return rd.payload.page[0].display;
        } else {
          return value;
        }
      })
    );
  }

  getLinkByPoint(point: StatletPoint): StatletPointRoute {
    return this.getLinkByValue(point.label);
  }

  getLinkByValue(value: string): StatletPointRoute {
    return { href: getStatletPagePath('SiteTopItemsWithDataType'), queryParams: { ['f.visitor.countryCode']: value } };
  }
}
