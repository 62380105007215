/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { hasNoValue } from '../../../../../../app/shared/empty.util';

/**
 * The options to use for displaying the collapsible list view below a Statlet
 */
/* tslint:disable:max-classes-per-file */
export class StatletGraphTypeListViewOptions {
  enabled: boolean;
  colours: boolean;
  inverseData: boolean;
  rowLabelAxis: 'x' | 'y' | 'z';
  columnLabelAxis: 'x' | 'y' | 'z';

  constructor(enabled: boolean = true, colours: boolean = true, inverseData: boolean = null, rowLabelAxis: 'x' | 'y' | 'z' = 'x', columnLabelAxis: 'x' | 'y' | 'z' = 'y') {
    this.enabled = enabled;
    this.colours = colours;
    this.inverseData = inverseData;
    this.rowLabelAxis = rowLabelAxis;
    this.columnLabelAxis = columnLabelAxis;
  }
}

/**
 * Configuration of a single graph type for a Statlet
 */
export class StatletGraphType {
  /**
   * Identifier of the graph type
   */
  id: string;

  /**
   * The font-awesome class to use for the graph's icon
   */
  fa: string;

  /**
   * The class to use for the graph's default width (when a custom width on the returned Statlet is missing)
   */
  defaultWidth: StatletGraphWidth;

  /**
   * Whether or not a graph should be rendered on top of the standalone page for this type
   * Optional, defaults to false
   */
  renderGraphOnStandalone: boolean;

  /**
   * The options to use for displaying the collapsible list view below a Statlet
   */
  listViewOptions: StatletGraphTypeListViewOptions;

  /**
   * Whether or not the data should be read inversely
   * (points resemble columns instead of row and the point's values resemble rows instead of columns)
   * See {@link DynamicSingleStatletComponent} for how the data is read regularly and inversely
   */
  inverseData: boolean;

  constructor(id: string, fa: string, defaultWidth: StatletGraphWidth, listViewOptions = new StatletGraphTypeListViewOptions(), renderGraphOnStandalone = false, inverseData = false) {
    this.id = id;
    this.fa = fa;
    this.defaultWidth = defaultWidth;
    this.listViewOptions = listViewOptions;
    this.renderGraphOnStandalone = renderGraphOnStandalone;
    this.inverseData = inverseData;
  }
}

/**
 * Configuration of the width of a {@link Statlet}
 */
export class StatletGraphWidth {
  id: string;
  classes: string;

  constructor(id: string, classes: string) {
    this.id = id;
    this.classes = classes;
  }
}

/**
 * Configuration of the Statlet widths
 * The values contain a class that will be applied to the card displaying the Statlet
 */
export const StatletGraphWidths = {
  SMALL: new StatletGraphWidth('small', 'col-sm-6 col-md-4 col-xs-12'),
  MEDIUM: new StatletGraphWidth('medium', 'col-xs-12 col-sm-6'),
  LARGE: new StatletGraphWidth('large', 'col-12'),
};

/**
 * Configuration of all statlet graph types
 */
export const StatletGraphTypes = {
  BAR_HORIZONTAL: new StatletGraphType('bar-horizontal', 'chart-bar flip-horizontal-rotate-90', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true, true, 'y', 'z')),
  BAR_HORIZONTAL_STACKED: new StatletGraphType('bar-horizontal-stacked', 'chart-bar flip-horizontal-rotate-90', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true,true, 'y', 'z')),
  BAR_VERTICAL: new StatletGraphType('bar-vertical', 'chart-bar', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true, true, 'x', 'z')),
  BAR_VERTICAL_STACKED: new StatletGraphType('bar-vertical-stacked', 'chart-bar', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true, true, 'x', 'z')),
  LINE_HORIZONTAL: new StatletGraphType('line-horizontal', 'chart-line', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true, null, 'z', 'x'), false, true),
  LIST: new StatletGraphType('list', 'list', StatletGraphWidths.MEDIUM, new StatletGraphTypeListViewOptions(false, false)),
  TABLE: new StatletGraphType('table', 'table', StatletGraphWidths.MEDIUM, new StatletGraphTypeListViewOptions(false, false)),
  PIE: new StatletGraphType('pie', 'chart-pie', StatletGraphWidths.SMALL),
  DONUT: new StatletGraphType('donut', 'chart-pie', StatletGraphWidths.SMALL),
  MAP: new StatletGraphType('map', 'map-marked-alt', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, false), true),
  NUMBER: new StatletGraphType('number', 'hashtag', StatletGraphWidths.SMALL, new StatletGraphTypeListViewOptions(false, false)),
  GAUGE: new StatletGraphType('gauge', 'tachometer-alt', StatletGraphWidths.SMALL),
  AREA: new StatletGraphType('area', 'chart-area', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true, null, 'z', 'x'), false, true),
  AREA_STACKED: new StatletGraphType('area-stacked', 'chart-area', StatletGraphWidths.LARGE, new StatletGraphTypeListViewOptions(true, true, null, 'z', 'x'), false, true),
};

/**
 * A serializer for automatically serializing and deserializing a json response property to and from a {@link StatletGraphType} object
 * Uses the StatletGraphTypes object above to find the appropriate StatletGraphType
 * Displays a warning in the developer console if no matching type is configured
 */
export const StatletGraphTypeSerializer = {
  Serialize(type: StatletGraphType): any {
    return type.id;
  },

  Deserialize(json: any): StatletGraphType {
    let type = getGraphTypeById(json);
    if (hasNoValue(type)) {
      console.warn(`No StatletGraphType is defined for type \"${json}\"`);
      type = StatletGraphTypes.LIST;
    }
    return type;
  }
};

/**
 * Find a graph type in the configuration object by its ID
 * @param id
 */
function getGraphTypeById(id: string): StatletGraphType {
  return Object.values(StatletGraphTypes).find((graph) => graph.id === id);
}

/**
 * A serializer for automatically serializing and deserializing a json response property to and from a {@link StatletGraphWidth} object
 * Uses the StatletGraphWidths object above to find the appropriate StatletGraphWidth
 * Displays a warning in the developer console if no matching width is configured
 */
export const StatletGraphWidthSerializer = {
  Serialize(width: StatletGraphWidth): any {
    return width.id;
  },

  Deserialize(json: any): StatletGraphWidth {
    const width = getGraphWidthById(json);
    if (hasNoValue(width)) {
      console.warn(`No StatletGraphWidth is defined for width \"${json}\"`);
      return null;
    }
    return width;
  }
};

/**
 * Find a graph width in the configuration object by its ID
 * @param id
 */
function getGraphWidthById(id: string): StatletGraphWidth {
  return Object.values(StatletGraphWidths).find((width) => width.id === id);
}

/**
 * Get a list of accepted widths in the form of a comma separated string
 */
export function getAcceptedGraphWidths(): string {
  return Object.values(StatletGraphWidths).map((width) => `\"${width.id}\"`).join(', ');
}
/* tslint:enable:max-classes-per-file */
