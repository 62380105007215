/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts an input into a decimal, displaying 3 digits after the decimal point by default
 */
@Pipe({ name: 'dsDecimal' })
export class DsDecimalPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, digitsInfo: string = '1.3-3', locale?: string): any {
    return super.transform(value, digitsInfo, locale);
  }
}
