/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AppState } from '../../../../../../app/app.reducer';

/**
 * This is how the statlet state is retrieved from the complete app state
 * @param state The complete app state
 */
export const statletStateSelector = (state: AppState) => state.statlets;
