<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'person.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="[object?.firstMetadata('person.familyName'), object?.firstMetadata('person.givenName')]" [separator]="', '"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'person.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <atmire-avs-player [item]="object"></atmire-avs-player>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async"
                    [defaultImage]="'assets/images/person-placeholder.svg'"
                    [alt]="'thumbnail.person.alt'"
                    [placeholder]="'thumbnail.person.placeholder'">
      </ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['person.email']"
                                [label]="'person.page.email'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['person.birthDate']"
                                [label]="'person.page.birthdate'">
    </ds-generic-item-page-field>
    <!--<ds-generic-item-page-field [item]="object"-->
    <!--[fields]="['person.identifier.staffid']"-->
    <!--[label]="'person.page.staffid'">-->
    <!--</ds-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-generic-item-page-field [item]="object" [fields]="['person.jobTitle']" [label]="'person.page.jobtitle'">
    </ds-generic-item-page-field>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPerson'"
      [label]="'person.page.orgunits' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object" [fields]="['dc.description']" [renderHTML]="true"
      [label]="'author.item.page.description'">
    </ds-themed-generic-item-page-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.author.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['person.identifier.orcid']"
      [label]="'person.page.orcid'">
    </ds-item-page-uri-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isAuthorOfPublication',
                                          filter: 'isAuthorOfPublication',
                                          configuration: 'authorOfPublication'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
