/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ReportConfig } from '../../../../shared-reports/config/report-config.model';
import { hasValue } from '../../../../../../app/shared/empty.util';
import { Params } from '@angular/router';

export class StatletConfig extends ReportConfig<StatletConfig> {
  dso: string;
  shortName: string;
  reportSize?: number;
  sort?: string;

  /**
   * Transform this configuration into a search link containing all options as parameters
   * @param href
   */
  toSearchHref(href: string): string {
    const params = {} as Params;
    if (hasValue(this.reportSize)) {
      params.reportSize = this.reportSize;
    }
    if (hasValue(this.sort)) {
      params.statletSort = this.sort;
    }

    return super.toHrefWithParams(`${href}/${this.dso}_${this.shortName}`, params);
  }
}
