/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AtmireSavedItemListState } from './atmire-saved-item-list.reducer';

export const atmireSavedItemListSelector = createFeatureSelector<AtmireSavedItemListState>('atmireSavedItemList');

/**
 * Select the current list ID from our {@link AtmireSavedItemListState}
 */
export const atmireSavedItemListsSelector =
  (): MemoizedSelector<AtmireSavedItemListState, { [key: string]: string }> => createSelector(
    atmireSavedItemListSelector, (atmireSavedItemListState: AtmireSavedItemListState) => atmireSavedItemListState.currentLists);
