import { InjectionToken } from '@angular/core';
// import mockSubmissionResponse from './mock-submission-response.json';
// import mockUsageStatisticsDSOConfigResponse from './mock-usage-statistics-dso-config-response.json';
// import mockUsageStatisticsMetadataConfigResponse from './mock-usage-statistics-metadata-config-response.json';
// import mockUsageStatisticsVisitorsConfigResponse from './mock-usage-statistics-visitors-config-response.json';
// import mockUsageStatisticsRelatedItemsConfigResponse from './mock-usage-statistics-related-items-config-response.json';
// import mockUsageStatisticsItemByRelationConfigResponse from './mock-usage-statistics-item-by-relation-config-response.json';
// import mockPublicationResponse from './mock-publication-response.json';
// import mockUntypedItemResponse from './mock-untyped-item-response.json';

export class ResponseMapMock extends Map<string, any> {}

export const MOCK_RESPONSE_MAP: InjectionToken<ResponseMapMock> = new InjectionToken<ResponseMapMock>('mockResponseMap');

/**
 * List of endpoints with their matching mock response
 * Note that this list is only used in development mode
 * In production the actual endpoints on the REST server will be called
 */
export const mockResponseMap: ResponseMapMock = new Map([
  // [ '/config/submissionforms/traditionalpageone', mockSubmissionResponse ]
  // [ '/statistics/usagestatisticstablesearchlists/config/dso', mockUsageStatisticsDSOConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/metadata', mockUsageStatisticsMetadataConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/visitors', mockUsageStatisticsVisitorsConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/itembyrelation', mockUsageStatisticsItemByRelationConfigResponse ],
  // [ '/statistics/usagestatisticstablesearchlists/config/relateditems', mockUsageStatisticsRelatedItemsConfigResponse ],
  // [ '/api/pid/find', mockPublicationResponse ],
  // [ '/api/pid/find', mockUntypedItemResponse ],
]);
