/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AuthenticatedGuard } from '../../../app/core/auth/authenticated.guard';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * Prevent unauthorized activating and loading of routes when property "atmire.savedItemLists.anonymous" is set to false
 */
@Injectable()
export class AtmireSavedItemListAuthenticatedGuard extends AuthenticatedGuard {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!environment.atmire.savedItemLists.anonymous) {
      return super.canActivate(route, state);
    } else {
      return observableOf(true);
    }
  }
}
