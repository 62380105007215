/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { rendersSingleStatletWrapper } from '../single-statlet-wrapper.decorator';
import { SingleStatletWrapperComponent } from '../single-statlet-wrapper.component';
import { Context } from '../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-atmire-cua-single-statlet-inline',
  templateUrl: './single-statlet-inline.component.html',
  styleUrls: ['./single-statlet-inline.component.scss']
})
@rendersSingleStatletWrapper(Context.StatletInline)
export class SingleStatletInlineComponent extends SingleStatletWrapperComponent {
}
