<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="!dismissed" class="alert {{type}} alert-dismissible fade show w-100 {{classes}}" role="alert" [@enterLeave]="animate">
  <span *ngIf="content" [innerHTML]="content | translate"></span>

  <ng-content></ng-content>

  <button *ngIf="dismissible" type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
