/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { interpolateNumber, interpolateRgb } from 'd3-interpolate';
import { isEmpty } from '../../../app/shared/empty.util';

/**
 * A service with helper methods for colors in charts
 */
@Injectable({
  providedIn: 'root'
})
export class AtmireCuaColorService {
  private defaultPalette: string[] = [
    'rgb(0,63,92)',    // #003f5c
    'rgb(68,78,134)',  // #444e86
    'rgb(149,81,150)', // #955196
    'rgb(221,81,130)', // #dd5182
    'rgb(255,110,84)', // #ff6e54
    'rgb(255,166,0)',  // #ffa600
  ];

  /**
   * Generate a set of the specified number of colors based on the given palette.
   *
   * The first and last colors in the palette will always be used exactly, the other colors will be
   * put in between, at equal intervals. If there's no exact match for a position in the original
   * palette, a new color will be generated for that exact position by interpolating between the two
   * neighboring colors
   *
   * @param nbColors  The required number of colors in the set. Should be a positive integer
   * @param palette   An optional palette to start from. If left out, the default palette is used.
   *                  A palette should have at least 2 colors and is assumed to be ordered in a
   *                  gradient
   */
  getColorSet(nbColors: number, palette: string[] = this.defaultPalette): string[] {
    if (!Number.isInteger(nbColors) || nbColors < 1) {
      throw new Error(`${nbColors} is not a valid number of colors`);
    }

    if (isEmpty(palette) || palette.length === 1) {
      throw new Error(`${JSON.stringify(palette, null, 2)} is not a valid palette`);
    }

    if (nbColors === 1) {
      // if only one color is required, always return the first color.
      return [palette[0]];
    }

    // create a function that, when passed a float between 0 and 1, will return the corresponding
    // float between 0 and palette.length - 1
    // e.g. interpolateNumber(0, 30)(0.5) = 15
    const indexFn = interpolateNumber(0, palette.length - 1);

    // generate an array of integers between 0 and nbColors
    // e.g. [0,1,2,3,4] for nbColors = 5
    return Array.from(Array(nbColors).keys())
      // divide each value by (nbColors - 1) to get values between 0 and 1
      .map((i: number) => i / (nbColors - 1))
      // run the interpolate function on them to get the closest matching index in the palette
      .map((i: number) => indexFn(i))
      .map((i: number) => {
        if (Number.isInteger(i)) {
          // if the number matches an index exactly, return the color at that index
          return palette[i];
        } else {
          // otherwise interpolate between the two colors the index falls in between
          const startColor = palette[Math.floor(i)];
          const endColor = palette[Math.ceil(i)];
          return interpolateRgb(startColor, endColor)(i - Math.floor(i));
        }
      });

  }
}
