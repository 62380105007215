/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, Injector } from '@angular/core';
import { rendersSingleStatletForType } from '../../single-statlet.decorator';
import { StatletGraphTypes } from '../../statlet-graph-types.model';
import { MergedSingleStatletComponent } from '../../merged-single-statlet/merged-single-statlet.component';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { AtmireCuaColorService } from '../../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../../../app/shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../../app/core/shared/generic-constructor';

@Component({
  selector: 'ds-atmire-cua-single-statlet-number-inline',
  templateUrl: './single-statlet-number-inline.component.html',
  styleUrls: ['./single-statlet-number-inline.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.NUMBER, Context.StatletInline)
/**
 * Component rendering a single statlet of type "number" in an inline context
 * Displays a single number
 */
export class SingleStatletNumberInlineComponent extends MergedSingleStatletComponent {
  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }
}
