/**
 * Class representing an option for the my-list item control
 * Contains the lists's ID, name and whether or not the item is selected within this list
 */
export class AtmireMyListItemControlOption {
  listId: string;
  listName: string;
  selected: boolean;

  constructor(listId: string, listName: string, selected: boolean) {
    this.listId = listId;
    this.listName = listName;
    this.selected = selected;
  }
}
