/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SearchService } from '../../../../app/core/shared/search/search.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getAllSucceededRemoteDataPayload } from '../../../../app/core/shared/operators';
import { hasNoValue, hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { SearchFilterConfig } from '../../../../app/shared/search/models/search-filter-config.model';

@Component({
  selector: 'ds-multi-facet-value-selector',
  styleUrls: ['./multi-facet-value-selector.component.scss'],
  templateUrl: './multi-facet-value-selector.component.html'
})
/**
 * A component rendering an input able to store multiple facet values and the ability to dynamically search for them
 */
export class MultiFacetValueSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filterConfig: SearchFilterConfig;

  /**
   * A list of selected facet labels
   */
  @Input() selectedLabels: string[] = [];

  /**
   * Placeholder text to display
   */
  @Input() placeholder: string = null;

  /**
   * Emits the list of selected labels whenever the user adds or removes a facet value
   */
  @Output() submitLabels: EventEmitter<string[]> = new EventEmitter();

  /**
   * The initially selected labels. Used to compare and check whether sending an update is necessary.
   */
  initialSelectedLabels: string[] = [];

  /**
   * The query entered by the user
   */
  query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * A list of options to display in the dropdown
   */
  options$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  /**
   * Whether or not data is loading
   * This will display a loading indicator when set to true
   */
  loading = false;

  /**
   * List of subscriptions to unsubscribe from
   */
  private subs: Subscription[] = [];

  constructor(protected searchService: SearchService) {
  }

  ngOnInit(): void {
    this.initialSelectedLabels = this.selectedLabels;
    this.updateOptionsFromQuery();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasValue(changes.filters)) {
      this.ngOnDestroy();
      this.subs = [];
      this.updateOptionsFromQuery();
    }
  }

  /**
   * Subscribe to the query and update the options accordingly
   */
  updateOptionsFromQuery() {
    this.subs.push(
      // Update the list of options to display in the dropdown depending on the current query entered by the user
      this.query$.pipe(
        switchMap((query) => {
          this.loading = true;
          return this.searchService.getFacetValuesFor(
            this.filterConfig, 1, null, query.toLowerCase()
          ).pipe(
            getAllSucceededRemoteDataPayload(),
            map((values) => {
              const options = values.page.map((value) => value.label);
              const selectedOptions = this.selectedLabels;
              const selectedTopOptions = options.filter((option) => hasValue(selectedOptions.find((selectedOption) => selectedOption === option)));
              const otherOptions = options.filter((option) => hasNoValue(selectedTopOptions.find((selectedTopOption) => selectedTopOption === option)));
              const otherSelectedOptions = selectedOptions.filter((selectedOption) => hasNoValue(selectedTopOptions.find((selectedTopOption) => selectedTopOption === selectedOption)));
              const finalOptions = [...selectedTopOptions];
              if (isNotEmpty(query)) {
                // If a query is provided, show the selected options from the list of results on top and the remaining results below
                finalOptions.push(...otherOptions);
              } else {
                // If no query is provided, show the selected options from the list of results on top, the other selected options below them and the remaining results below those
                finalOptions.push(...otherSelectedOptions.slice(0, this.filterConfig.pageSize - finalOptions.length));
                finalOptions.push(...otherOptions.slice(0, this.filterConfig.pageSize - finalOptions.length));
              }
              return finalOptions;
            })
          );
        }),
      ).subscribe((options) => {
        this.loading = false;
        this.options$.next(options);
      }),
    );
  }

  /**
   * Send an update to the parent component
   * Only send an update if the labels are different from the initially selected labels
   * @param labels
   */
  submit(labels: string[]) {
    if (!this.labelsEqualInitial(labels)) {
      this.submitLabels.emit(labels);
    }
  }

  /**
   * The list of selected labels changed
   */
  change() {
    this.reloadList();
  }

  /**
   * Reload the list by emitting the current query again
   * This is used to update the list of options whenever selectedLabels updates
   */
  reloadList() {
    this.query$.next(this.query$.value);
  }

  /**
   * Method to check if a list of labels match the list of initially selected labels
   * @param labels
   */
  labelsEqualInitial(labels: string[]): boolean {
    return labels.length === this.initialSelectedLabels.length &&
      labels.every((e) => this.initialSelectedLabels.includes(e)) &&
      this.initialSelectedLabels.every((e) => labels.includes(e));
  }

  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
