/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ItemListPreviewComponent as BaseComponent } from '../../../../../../../app/shared/object-list/my-dspace-result-list-element/item-list-preview/item-list-preview.component';
import { Component, Input } from '@angular/core';
import { Context } from '../../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-atmire-item-list-preview',
  styleUrls: ['../../../../../../../app/shared/object-list/my-dspace-result-list-element/item-list-preview/item-list-preview.component.scss'],
  templateUrl: './item-list-preview.component.html'
})
export class ItemListPreviewComponent extends BaseComponent {
  @Input() context: Context;
}
