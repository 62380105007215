/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  BaseChartComponent,
  calculateViewDimensions,
  ColorHelper, gridLayout, gridSize,
  ViewDimensions
} from '@swimlane/ngx-charts';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-ngx-custom-number-inline',
  templateUrl: './ngx-custom-number-inline.component.html',
  styleUrls: ['./ngx-custom-number-inline.component.scss']
})
export class CustomNumberInlineComponent extends BaseChartComponent {
  @Input() cardColor: string;
  @Input() bandColor: string;
  @Input() emptyColor = 'rgba(0, 0, 0, 0)';
  @Input() innerPadding = 0;
  @Input() textColor: string;
  @Input() valueFormatting: any;
  @Input() labelFormatting: any;
  @Input() designatedTotal: number;

  dims: ViewDimensions;
  data: any[];
  slots: any[];
  colors: ColorHelper;
  transform: string;
  domain: any[];
  margin = [0, 0, 0, 0];

  backgroundCards: any[];

  get clickable() {
    return !!this.select.observers.length;
  }

  update(): void {
    super.update();

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin
    });

    this.formatDates();

    this.domain = this.getDomain();

    this.setColors();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

    const size = gridSize(this.dims, this.results.length, 150);
    const N = size[0] * size[1];

    const data = this.results.slice();

    while (data.length < N) {
      data.push({ value: null });
    }

    this.data = gridLayout(this.dims, data, 150, this.designatedTotal);
  }

  getDomain(): any[] {
    return this.results.map(d => d.label);
  }

  onClick(data): void {
    this.select.emit(data);
  }

  setColors(): void {
    this.colors = new ColorHelper(this.scheme, 'ordinal', this.domain, this.customColors);
  }
}
