/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, autoserializeAs } from 'cerialize';
import {
  StatletGraphWidth,
  StatletGraphWidthSerializer
} from '../../single-statlet/graph-types/statlet-graph-types.model';

export class StatletStyle {
  @autoserializeAs(StatletGraphWidthSerializer)
  width: StatletGraphWidth;

  @autoserialize
  palette: string[];
}
