/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { link, typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { autoserialize, autoserializeAs, deserialize, inheritSerialization } from 'cerialize';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { SEARCH_REPORT } from './search-report.resource-type';
import { HALLink } from '../../../../app/core/shared/hal-link.model';
import { ITEM } from '../../../../app/core/shared/item.resource-type';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';
import { SearchTerm } from './search-term.model';
import { SEARCH_TERM } from './search-term.resource-type';

/**
 * Class representing a DSpace SearchReport
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class SearchReport extends DSpaceObject {
  static type = SEARCH_REPORT;

  /**
   * The identifier of this SearchReport
   * The ID is the base64 encoding of each parameter, using a new line per parameter
   * Example parameters are: uri, start, end
   */
  @autoserialize
  id: string;

  /**
   * The scope of this search report
   * This is the ID of the object this SearchReport resembles
   */
  @autoserialize
  scope: string;

  /**
   * The search query this report stores statistics for
   */
  @autoserialize
  query: string;

  /**
   * The earliest date this report is storing statistics for
   */
  @autoserializeAs('start_date')
  startDate: Date;

  /**
   * The latest date this report is storing statistics for
   */
  @autoserializeAs('end_date')
  endDate: Date;

  /**
   * Whether or not the report includes facets
   */
  @autoserializeAs('include_facets')
  includeFacets: boolean;

  /**
   * The total amount of searches this report is storing statistics for
   */
  @autoserializeAs('total_searches')
  totalSearches: number;

  /**
   * The total amount of page views this report is storing statistics for
   */
  @autoserializeAs('total_pageviews')
  totalPageviews: number;

  @deserialize
  _links: {
    self: HALLink;
    dspaceobject: HALLink;
    searchterms: HALLink;
  };

  /**
   * The DSpaceObject this report is storing statistics for
   */
  @link(ITEM)
  dspaceobject?: Observable<RemoteData<DSpaceObject>>;

  /**
   * The list of {@link SearchTerm}s for this report
   */
  @link(SEARCH_TERM, true)
  searchterms?: Observable<RemoteData<PaginatedList<SearchTerm>>>;
}
